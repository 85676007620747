import React, { useRef, useState } from "react";
import "./Updates.css";
import profie from "../../imgs/profile.png";
import { useUserAuth } from "../../Context/UseAuthContext";
import { TbPhotoEdit } from "react-icons/tb";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Input,
} from "@mui/material";
import toast from "react-hot-toast";

const Updates = () => {
  const { user, getUserData, darkThemesetter } = useUserAuth();

  const [updatevalue, setUpdatevalue] = useState("");
  const [showProfile, setProfileShowing] = useState(false);
  const [updateName, setUpdateName] = useState("");
  const [updateEmail, setUpdateEmail] = useState("");
  const [updateNumber, setUpdateNumber] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const fileInputRef = useRef(null);

  const dialogContentContainer = darkThemesetter
    ? "dialog-content-contianer-dark-theme"
    : "light-theme";

  const handleEditName = (v) => {
    setUpdatevalue(v);
  };
  const handleUpdate = () => {
    const id = user._id;
    let payload;
    if (updatevalue === "name" && updateName) {
      payload = { name: updateName };
    } else if (updatevalue === "email" && updateEmail) {
      payload = { email: updateEmail };
    } else if (updatevalue === "number" && updateNumber) {
      payload = { number: updateNumber };
    } else {
      payload = { profileUrl: profileUrl };
    }

    fetch(`https://smartuter.com/api/admin/edit/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        setUpdatevalue("");
        getUserData();
      })
      .catch((err) => console.log(err));
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file.size > 1 * 1024 * 1024) {
      toast.error("Upload File Less Than 1 MB", {
        duration: 6000,
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    const response = await fetch(
      "https://smartuter.com/api/enduser/uploadimage",
      {
        method: "POST",
        body: formData,
      }
    );
    if (response.ok) {
      const data = await response.json();
      setProfileUrl(data.fileUrl);
    } else {
      console.error("Error uploading file:", response.statusText);
      throw new Error("Upload failed");
    }
    // Handle the selected file (e.g., upload or display it)
  };
  return (
    <div className="Updates">
      {/* {UpdatesData.map((update) => {
        return ( */}
      <div className="update" onClick={() => setProfileShowing(true)}>
        <img src={user.profileUrl ? user.profileUrl : profie} alt="profile" />
        <div className="noti">
          <div style={{ marginBottom: "0.5rem" }}>
            {/* <h5>{user.name}</h5> */}
            {/* <span>Name:- {user.name}</span> */}
          </div>
        </div>
      </div>
      {/* );
      })} */}
      {/* profile showing detail and edit */}
      <Dialog
        className="admitdashboard-popup"
        open={showProfile}
        onClose={() => {
          setProfileShowing(false);
          setUpdatevalue("");
        }}
        // PaperProps={{
        //   style: {
        //     height: "80vh",
        //     width: "60vw",
        //   },
        // }}
        classes={{ paper: "responsive-dialog-paper" }}
      >
        <div className={dialogContentContainer}>
          <DialogTitle>Profile</DialogTitle>
          <DialogContent className="profile-content-container">
            <div style={{ marginBottom: "0.5rem", display: "grid" }}>
              <span>
                <div className="profile-img-sub-container">
                  <div
                    className="clickable-area"
                    onClick={() => fileInputRef.current.click()}
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <img
                      src={user.profileUrl ? user.profileUrl : profie}
                      alt="Profile"
                      className="profile-image"
                    />
                    <div className="upload-img-icon">
                      <TbPhotoEdit />
                    </div>
                  </div>

                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  {profileUrl && (
                    <Button
                      type="button"
                      className="profile-upload-button"
                      onClick={handleUpdate}
                    >
                      <TbPhotoEdit />
                      update
                    </Button>
                  )}
                </div>
              </span>
              <span
                style={{
                  overflowX: "hidden", // Change this to your desired color
                }}
              >
                Name:-
                {updatevalue === "name" ? (
                  <>
                    <Input
                      placeholder="Enter Your Name"
                      onChange={(e) => setUpdateName(e.target.value)}
                      required
                    />
                    <Button onClick={handleUpdate}>Update</Button>
                  </>
                ) : (
                  <>
                    {user.name}
                    <Button onClick={() => handleEditName("name")}>Edit</Button>
                  </>
                )}
              </span>

              <span>
                Email:-
                {updatevalue === "email" ? (
                  <>
                    <Input
                      placeholder="Enter Your Email"
                      onChange={(e) => setUpdateEmail(e.target.value)}
                      sx={{ overflowX: "hidden" }}
                      required
                    />
                    <Button onClick={handleUpdate}>Update</Button>
                  </>
                ) : (
                  <>
                    {user.email}
                    <Button onClick={() => handleEditName("email")}>
                      Edit
                    </Button>
                  </>
                )}
              </span>

              <span>
                Number:-
                {updatevalue === "number" ? (
                  <>
                    <Input
                      type="number"
                      placeholder="Enter Your Number"
                      onChange={(e) => setUpdateNumber(e.target.value)}
                    />
                    <Button onClick={handleUpdate}>Update</Button>
                  </>
                ) : (
                  <>
                    {user.number}
                    <Button onClick={() => handleEditName("number")}>
                      Edit
                    </Button>
                  </>
                )}
              </span>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default Updates;
