import "./analytics.css";
import { RiCoupon3Line } from "react-icons/ri";
import { FaRegPlayCircle } from "react-icons/fa";
import { FaGraduationCap } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { Line, Doughnut } from "react-chartjs-2";
import { useUserAuth } from "../../Context/UseAuthContext";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement
);
const Analytics = () => {
  const [selectedOption, setSelectedOption] = useState("This Week");

  const { darkThemesetter } = useUserAuth();
  const ModeBasedColor = darkThemesetter
    ? "darktheme-dashboard"
    : "lighttheme-dashboard";
  const cardItemsInDashBoard = darkThemesetter
    ? "darktheme-dashboard-card"
    : "lighttheme-dashboard-card";
  const textColor = darkThemesetter ? "dark-text" : "light-text";
  const [stats, setStats] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch(
          "https://smartuter.com/api/all-sub-admins/totalstats/totalcourse"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setStats(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchStats();
  }, []);

  return (
    <div className={`SectionDashBoardVendorAnalytics ${ModeBasedColor}`}>
      <div className="SectionDashBoardVendorAnalyticsHeader">
        <h1 className={textColor}>DashBoard</h1>
        <p>Wellcome to your DashBoard,Represented your data</p>
      </div>

      <div className="SectionDashBoardVendorAnalyticsCards">
        <div className="SectionDashBoardVendorAnalyticsCards">
          <div
            className={`SectionDashBoardVendorAnalyticsCard ${cardItemsInDashBoard}`}
          >
            <div className="SectionDashBoardVendorAnalyticsCardInfo">
              <div>Total Sales</div>
              <div>
                <p>{stats && stats.totalSalesCount}</p>
              </div>
            </div>

            <div className="SectionDashBoardVendorAnalyticsCardImage">
              <RiCoupon3Line size={40} color={"#6440fb"} />
            </div>
          </div>

          <div
            className={`SectionDashBoardVendorAnalyticsCard ${cardItemsInDashBoard}`}
          >
            <div className="SectionDashBoardVendorAnalyticsCardInfo">
              <div>Total Courses</div>
              <div>
                <strong>{stats && stats.totalCourses}</strong>
              </div>
            </div>

            <div className="SectionDashBoardVendorAnalyticsCardImage">
              <FaRegPlayCircle size={40} color={"#6440fb"} />
            </div>
          </div>

          <div
            className={`SectionDashBoardVendorAnalyticsCard ${cardItemsInDashBoard}`}
          >
            <div className="SectionDashBoardVendorAnalyticsCardInfo">
              <div>Total Payments</div>
              <div>
                <strong>{stats && stats.totalAmount}</strong>
              </div>
            </div>

            <div className="SectionDashBoardVendorAnalyticsCardImage">
              <FaGraduationCap size={40} color={"#6440fb"} />
            </div>
          </div>
        </div>
      </div>

      <div className="SectionDashBoardVendorAnalyticsStatistics Graph-board">
        <div
          className={`SectionDashBoard-round-graph-one ${cardItemsInDashBoard}`}
        >
          <div className="SectionDashBoardVendorAnalyticsStatisticsGraphChart Graph-section-bar">
            <p>Earning Statistics</p>
            {/* <div
              className={`SectionDashBoardVendorAnalyticsStatisticsGraphChartHeader${cardItemsInDashBoard}`}
            >
              <select
                className="SelectDataRange"
                value={selectedOption}
                onChange={handleChange}
              >
                <option value="This Week">This Week</option>
                <option value="Animation">Animation</option>
                <option value="Design">Design</option>
                <option value="Illustration">Illustration</option>
                <option value="Business">Business</option>
              </select>
            </div> */}
          </div>
          {/* <div className="analatics-dashbord-main"> */}
          <div className="sectionDashBoardVenodrAnalyticsStaticalGraph-Dought Graph-section-Dought">
            <div className="SectionDashBoardVendorAnalyticsStatisticsLineChart">
              <Line
                data={{
                  // x-axis label values
                  labels: ["totalAmount", "totalCourses", "totalSalesCount"],
                  datasets: [
                    {
                      label: "",
                      data: stats && [
                        stats.totalAmount,
                        stats.totalCourses,
                        stats.totalSalesCount,
                      ],
                      fill: false,
                      borderWidth: 3,
                      backgroundColor: "white",
                      borderColor: "blue",
                      tension: 0.4,
                      responsive: true,
                      pointRadius: 3,
                      pointHoverRadius: 6,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        {/* <div className="SectionDashBoardVendorAnalyticsStatisticsDoughnutChartCard"> */}

        <div
          className={`SectionDashBoard-round-graph  ${cardItemsInDashBoard}`}
        >
          <div className="SectionDashBoardVendorAnalyticsStatisticsDoughnuttHeader Pie-chart-bar">
            <p>Earning Statistics</p>
            {/* <select
              className="SelectDataRange"
              value={selectedOption}
              onChange={handleChange}
            >
              <option value="This Week">This Week</option>
              <option value="Animation">Animation</option>
              <option value="Design">Design</option>
              <option value="Illustration">Illustration</option>
              <option value="Business">Business</option>
            </select> */}
          </div>
          <div className="SectionDashBoardVendorAnalyticsStatisticsDoughnutChart Pie-chart-data">
            <Doughnut
              data={{
                labels: ["totalAmount", "totalCourses", "totalSalesCount"],
                datasets: [
                  {
                    data: stats && [
                      stats.totalAmount,
                      stats.totalCourses,
                      stats.totalSalesCount,
                    ],
                    backgroundColor: ["#7153ef", "#6c4eeb", "#5837e4"],
                  },
                ],
              }}
              options={{
                cutout: 90,
              }}
            />
            <div className="DoughnutChartLabels">
              <p>
                <span></span>Orgnaic
              </p>
              <p>
                <span></span>Direct
              </p>
              <p>
                <span></span>Referal
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
