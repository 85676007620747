import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "../page/Home";

import { Payment } from "../page/payment/Payment";
import { Login } from "../page/Login";
import { SubAdmin } from "../page/subadmin/SubAdmin";
import PasswordReset from "../page/Passwordreset";
import ForgotPassword from "../page/ForgotPassword";
import PrivateRoutes from "./PrivateRoutes";
import Posts from "./SubadminTable/PostsSingeadmin/Posts";
import CreateCategory from "./CreateCategory/CreateCategory";
import Project from "../page/subadmin/Project";
import Projects from "./SubadminTable/ProjectsSingleadmin/Projects";
import StudyMaterial from "../page/subadmin/StudyMaterial";
import StudyMaterials from "./SubadminTable/StudyMaterialsSingleadmin/StudyMaterials";
import SubadminDetails from "./SubadminTable/SubadminDetails/SubadminDetails";
import AdminTree from "./Admintree/Admintree";
import EndUser from "./EndUsers/EndUser";
import SingleEndUser from "./EndUsers/SingleEndUser";
import CoursesPage from "./AllItems/Courses";
import ProjectsPage from "./AllItems/Projects";
import StudysMaterialPage from "./AllItems/StudysMaterial";


export const AllRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoutes>
            <Home />
          </PrivateRoutes>
        }
      />

      <Route
        path="/courses"
        element={
          <PrivateRoutes>
            <SubAdmin />
          </PrivateRoutes>
        }
      />
      <Route
        path="/projects"
        element={
          <PrivateRoutes>
            <Project />
          </PrivateRoutes>
        }
      />
      <Route
        path="/vendor"
        element={
          <PrivateRoutes>
            <StudyMaterial />
          </PrivateRoutes>
        }
      />
      <Route
        path="/subadmindetails/:userID"
        element={
          <PrivateRoutes>
            <SubadminDetails />
          </PrivateRoutes>
        }
      />
      <Route
        path="/studymaterilas/:userID"
        element={
          <PrivateRoutes>
            <StudyMaterials />
          </PrivateRoutes>
        }
      />
      <Route
        path="/courses/:userID"
        element={
          <PrivateRoutes>
            <Posts />
          </PrivateRoutes>
        }
      />
      <Route
        path="/projects/:userID"
        element={
          <PrivateRoutes>
            <Projects />
          </PrivateRoutes>
        }
      />
      <Route
        path="/payment"
        element={
          <PrivateRoutes>
            <Payment />
          </PrivateRoutes>
        }
      />
      <Route
        path="/referral"
        element={
          <PrivateRoutes>
            <AdminTree />
          </PrivateRoutes>
        }
      />
      {/* <Route path="/referral/:userId/:name" element={<AdminTree />} /> */}
      <Route
        path="/allendusers"
        element={
          <PrivateRoutes>
            <EndUser />
          </PrivateRoutes>
        }
      />
      <Route
        path="/allendusers/:id/:name"
        element={
          <PrivateRoutes>
            <SingleEndUser />
          </PrivateRoutes>
        }
      />

      <Route path="/login" element={<Login />} />
      <Route path="/passwordreset" element={<PasswordReset />} />
      <Route path="/forgotpassword/:id/:token" element={<ForgotPassword />} />
      <Route
        path="/create_category"
        element={
          <PrivateRoutes>
            <CreateCategory />
          </PrivateRoutes>
        }
      />
      {/* ALlitems */}
    <Route 
    path="/all-courses"
    element={
      <PrivateRoutes>
        <CoursesPage/>
      </PrivateRoutes>

    }/>
     <Route 
    path="/all-projects"
    element={
      <PrivateRoutes>
        <ProjectsPage/>
      </PrivateRoutes>

    }/>
     <Route 
    path="/all-studymaterials"
    element={
      <PrivateRoutes>
        <StudysMaterialPage/>
      </PrivateRoutes>

    }/>

    </Routes>
  );
};
