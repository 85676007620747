import React, { useState } from "react";
import { NavLink, useNavigate, Navigate } from "react-router-dom";
import { useUserAuth } from "../Context/UseAuthContext";
import toast from "react-hot-toast";

export const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const { getUserData } = useUserAuth();
  const handleSubmit = () => {
    const payload = {
      email,
      pass,
    };
    fetch("https://smartuter.com/api/admin/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.msg === "Login successful") {
          navigate("/");
          toast.success(`${email} Login successful`);
          setEmail("");
          setPass("");
          try {
            //
            toast.success(`${email} Login successful`);
            res.token && localStorage.setItem("admintoken", res.token);
            res.token && getUserData();
          } catch (error) {
            console.error("Error setting token in localStorage:", error);
          }
        } else {
          toast.error(`${res.msg}`);
        }
      })
      .catch((err) => {
        toast.error(`${err.message}`);
        toast.error(`${err.message} Server Error`);
      });
  };
  if (localStorage.getItem("admintoken")) {
    return <Navigate to="/" />;
  }
  return (
    <div className="main_login">
      <div className="login-container">
        <h1>Login</h1>
        <div className="input-group">
          <label>Email:</label>
          <input
            type="email"
            placeholder="Enter Your Email"
            value={email}
            className="login-input"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>Password:</label>
          <input
            type="password"
            placeholder="Enter Your password"
            value={pass}
            className="login-input"
            onChange={(e) => setPass(e.target.value)}
          />
        </div>
        <p>
          <NavLink to={"/passwordreset"} className={"navlink"}>
            Forgot password?
          </NavLink>
        </p>
        <button type="button" onClick={handleSubmit}>
          Login
        </button>
      </div>
    </div>
  );
};
