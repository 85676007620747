import React from "react";
import "./MainDash.css";
import { AllRoutes } from "../AllRoutes";
import { useUserAuth } from "../../Context/UseAuthContext";
const MainDash = () => {
  const {darkThemesetter} = useUserAuth()
  const mainDashTheme = darkThemesetter ? "dark-theme" : "light-theme";
  return (
    <div className={`MainDash ${mainDashTheme}`}>
  
      
      <AllRoutes/>
    </div>
  );
};

export default MainDash;
