import * as React from "react";
import "./Table.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useEffect } from "react";
import { useUserAuth } from "../../Context/UseAuthContext";
import { Link } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import logo from "../../imgs/profile.png";

const makeStyle = (status) => {

  if (status) {
    return {
      background: "rgb(145 254 159 / 47%)",
      color: "green",
    };
  } else {
    return {
      background: "#ffadad8f",
      color: "red",
    };
  }
};

const StudyMaterialTable = () => {
  const {
    token,
    getSubadminsData,
    subadmins,
    verifiedSubadmin,
    showVerifaction,
    setShowVerificationstatus,
    singleSubadmin,
    handleSubadminVerifaction,

    FilterAuth,
    searchQuary,
    orderFilter,
    handleFilterAuthClick,
    handleSearchVendorNameChange,
    handleOrderFilter,
    handleSelectFilterChange,
    darkThemesetter
  } = useUserAuth();


  const textColor = darkThemesetter ? "dark-text" : "light-text"


  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };


  useEffect(() => {
    if (token) {
      getSubadminsData();
    }
  }, [token, FilterAuth, searchQuary, orderFilter]);

  const myCoursesTheme = darkThemesetter ? "dark-theme" : "light-theme";
  const myCoursesDataTheme = darkThemesetter ? "mycourses-data-dark-theme" : "mycourses-data-light-theme"
  const mycoursesTextColor = darkThemesetter ? "dark-text" : "light-text";

  return (
    <div className={`mycourses ${myCoursesTheme}`}>
      <div className="SectionDashBoardVendorAnalyticsHeader">
        <div className="vndor-heading">
          {" "}
          <h1 className={textColor}>Vendors</h1>
          <p>Welcome to your Vendor, Represented your Vendor data</p>
        </div>
      </div>
      <div className={`mycourse-data ${myCoursesDataTheme}`}>
        <div className="mycourses-button">
          <button
            style={{ backgroundColor: FilterAuth === "" ? "#140342" : "" }}
            onClick={() => handleFilterAuthClick("")}
          >
            All Vendors 
          </button>
          <button
            style={{ backgroundColor: FilterAuth ? "#140342" : "" }}
            onClick={() => handleFilterAuthClick(true)}
          >
            Verified
          </button>
          <button
            style={{ backgroundColor: FilterAuth === false ? "#140342" : "" }}
            onClick={() => handleFilterAuthClick(false)}
          >
            Not Verified
          </button> 
        </div>
        <div className="dropdown-containers"> 
          <div className="search-container">
            <input
              type="text"
              placeholder="Search Vendor..."
              // value={searchQuery}
              onChange={handleSearchVendorNameChange}
            />
            <FiSearch className="mycourse-searchicon" />
          </div>

          <div className="review-2">
            <div className="dropdown-study-container category-2">
              {/* <div class="dropdown">
                <span value={orderFilter} onChange={handleOrderFilter}>Sort by</span>
                <div class="dropdown-content">
                  <p value={-1}>Sort By</p>
                  <p value={1}>Sort By Previous</p>
                </div>
                
              </div> */}
              <select
                className="adminBookmarksTypeSelection"
                id="approvalStatus"
                onChange={handleOrderFilter}
                value=""
              >
                <option value="">Sort by</option>
                <option value={1}>Newest</option>
                <option value={-1}>Oldest</option>
              </select>
              <div className="dropdown-arrow"></div>
            </div>
          </div>

          {/* <div className="review-2">
            <div class="dropdown-study-container category-2">
              <div class="dropdown">
                <span value={FilterAuth} onChange={handleSelectFilterChange}>All Vendor</span>
                <div class="dropdown-content">
                <p value={true}>Verified</p>
              <p value={false}>Not Verified</p>
                </div>
              </div>

              <div class="dropdown-arrow"></div>
            </div>
          </div> */}
        </div>

        <div className="mycourses-section1">
          {subadmins &&
            subadmins.map((course) => (
              <div key={course._id} className="mycourse-section-card">
                <div className="mycourse-section-cards">
                  <Link to={`/subadmindetails/${course._id}`}>
                    {course.profileUrl ? (
                      <img
                        src={course.profileUrl}
                        alt={course.name}
                        onError={(e) => {
                          e.target.src =
                            "https://www.timeoutdubai.com/cloud/timeoutdubai/2021/09/11/hfpqyV7B-IMG-Dubai-UAE-1200x800.jpg";
                        }}
                      />
                    ) : (
                      <img src={logo} alt={course.name} />
                    )}
                    <h4 className={mycoursesTextColor}>{truncateText(course.name, 15)}</h4>
                    
                    <span
                      className={`mycourse-section-cards-a ${mycoursesTextColor}`}
                    >
                     Details ...
                    </span>{" "}
                  </Link>
                  <button
                    style={makeStyle(course.isVerified)}
                    className="mycourses-btn"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the click event from propagating to the parent Link
                      verifiedSubadmin(course);
                    }}
                  >
                    {course.isVerified ? "Verified" : "Not Verified"}
                  </button>
                </div>
              </div> 
            ))}
        </div>

        {/* <CourseList courses={filteredCourses} /> */}
      </div>
      <Dialog
        open={showVerifaction}
        onClose={() => setShowVerificationstatus(false)}
      >
        <DialogTitle>
          Are You sure you want to give{" "}
          {singleSubadmin.isVerified ? "Not Verified" : "Verified"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowVerificationstatus(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubadminVerifaction}>
            {singleSubadmin.isVerified ? "Not Verified" : "Verified"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default StudyMaterialTable;
