import React from 'react'
import StudyMaterialTable from '../../components/SubadminTable/StudyMaterialTable'

const StudyMaterial = () => {
  return (
    <div>
      
        <StudyMaterialTable/>
    </div>
  )
}

export default StudyMaterial