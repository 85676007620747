import React from 'react'
import ProjectTable from '../../components/SubadminTable/ProjectTable'

const Project = () => {
  return (
    <div> 
    <ProjectTable/>
    </div>
  )
}

export default Project