import "./App.css";
import MainDash from "./components/MainDash/MainDash";
import Sidebar from "./components/Sidebar";
import { useUserAuth } from "./Context/UseAuthContext";
import NavbarDashboardAdmin from "./components/navbarDashbord/NavbarDashboardAdmin";
import { ColorRing } from "react-loader-spinner";
import ContentPage from "./components/contentpage/ContentPage";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import socketIOClient from 'socket.io-client';
import { Toaster } from "react-hot-toast";
function App() {
  const {loading, token, darkThemesetter ,updateSocket} = useUserAuth();
const navigate=useNavigate();
const ENDPOINT="https://smartuter.com";
useEffect(() => {
  const socket=socketIOClient(ENDPOINT);

  socket.on("dataChange",change=>{
    updateSocket(change)
  });
  return ()=>socket.disconnect();
  }, []);

// useEffect(()=>{
//   if(!token){
//     navigate("/login");
//   }
// },[token,navigate])
  const backgroundColor = darkThemesetter ? "app-dark-theme" : "app-light-theme";
 

  if (loading) {
    return (
      <div className="centered-container">
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={["#b0b2f6", "#9296f3", "#6d71f6", "#484ef6", "#272ef7"]}
        />
      </div>
    );
  }
  if (token) {
    return (
      <div>
        <div className="App">
          <Toaster />
          
          <NavbarDashboardAdmin />
          <div className={`AppGlass ${backgroundColor}`}>
            <Sidebar />
            <MainDash />
          </div>
        </div>
      </div>
    );
  }
  return <ContentPage />;
}

export default App;
