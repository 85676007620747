import React, { useState } from "react";
import "./notification.css";
import { useUserAuth } from "../../Context/UseAuthContext";
import { Link } from "react-router-dom";
import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import axios from "axios";
const Notification = ({ isNotificationPageVisible }) => {
  const { notification, getNotification, darkThemesetter } = useUserAuth();
  const [showNotificationDialog, setShowNotificationDialog] = useState(false);
  const [singleDialog, setSingleDialog] = useState(null);
  const darkClassName = darkThemesetter
    ? "notification-box-dark-theme"
    : "notification-box-light-theme";
  const notificationTextColor = darkThemesetter
    ? "notification-dark-text"
    : "notification-light-text";
  const updateValue = async (id) => {
    try {
      await axios.patch(
        "https://smartuter.com/api/notification/updateadminnotification",
        { id: id }
      );
      getNotification();
    } catch (error) {
      console.log(error.message);
    }
  };
  const deleteValue = async (id) => {
    try {
      await axios.patch(
        "https://smartuter.com/api/notification/deletenotification",
        { id: id }
      );
      getNotification();
    } catch (error) {
      console.log(error.message);
    }
  };
  const setDialog = (id) => {
    // setSingleDialog(e.data);
    // setShowNotificationDialog(true);
    updateValue(id);
  };
  console.log(notification);
  return (
    <div className={`notification-page ${darkClassName}`}>
      <div className="notification-list">
        {/* {notification &&
          notification.map((e, i) => ( 
            <Link key={i}>
              <div onClick={() => setDialog(e)}>
                <img
                  src={e.data.imageUrl}
                  alt={e.data.postName}
                  onError={(e) => {
                    e.target.src =
                      "https://www.timeoutdubai.com/cloud/timeoutdubai/2021/09/11/hfpqyV7B-IMG-Dubai-UAE-1200x800.jpg";
                  }}
                />
                <h6 className={notificationTextColor}>
                  {e.data.postName} (Purchased)
                </h6>
              </div>
            </Link>
          ))} */}
        {notification &&
          notification.map((notification) => {
            const { _id, data, NotificationTimestamp, AdminOpen } =
              notification;
            const {
              VendorID,
              userID,
              approve,
              email,
              number,
              vendor,
              enduser,
              imageUrl,
              postName,
              OutputScreens,
              ProjectTitle,
              MaterialImage,
              MaterialName,
              ID,
              reciverUser,
              senderUser,
            } = data;

            // Convert timestamp to readable date
            const notificationDate = new Date(
              NotificationTimestamp
            ).toLocaleString();

            return (
              <div
                key={_id}
                className={"notification"}
                style={{
                  backgroundColor: `${AdminOpen ? "#f0f0f0" : null}`,
                  fontWeight: `${AdminOpen ? "100" : "bold"}`,
                }}
                onClick={() => setDialog(_id)}
              >
                {/* {email && <p>Email: {email}</p>} */}
                {/* {number && <p>Number: {number}</p>} */}
                {vendor && <h4>Vendor for Verification</h4>}
                {vendor && <p>{vendor}</p>}

                {enduser && <h4>Enduser Register</h4>}
                {enduser && <p>{enduser}</p>}
                {/* {imageUrl && <img src={imageUrl} alt={postName} />} */}
                {postName && VendorID && <h4>Purchased</h4>}
                {postName && VendorID && <p>{postName}</p>}
                {/* {OutputScreens && (
              <div>
                <p>Output Screens:</p>
                <img src={OutputScreens} alt="Output Screens" />
              </div>
            )} */}
                {postName && userID && <h4>Course for Verification</h4>}
                {postName && userID && <p>{postName}</p>}
                {ProjectTitle && <h4>Project for Verification</h4>}
                {ProjectTitle && <p>{ProjectTitle}</p>}
                {/* {MaterialImage && <img src={MaterialImage} alt="Material" />} */}
                {MaterialName && <h4>StudyMaterial for Verification</h4>}
                {MaterialName && <p>{MaterialName}</p>}
                {/* {ID && <p>ID: {ID}</p>} */}
                {reciverUser && <h4>Referral</h4>}

                {reciverUser && <p>Receiver: {reciverUser}</p>}
                {senderUser && <p>Sender: {senderUser}</p>}
                {NotificationTimestamp && (
                  <p className="timestamp">At: {notificationDate}</p>
                )}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    className="button delete"
                    onClick={() => deleteValue(_id)}
                  >
                    DELETE
                  </button>
                  <button
                    className={`button ${AdminOpen ? "read" : "mark-as-read"}`}
                  >
                    {AdminOpen ? "READ" : "MARK AS READ"}
                  </button>
                </div>
              </div>
            );
          })}
      </div>

      {singleDialog && (
        <Dialog
          open={showNotificationDialog}
          onClose={() => setShowNotificationDialog(false)}
        >
          <DialogContent>
            <Typography variant="h5" component="div">
              {singleDialog.postName}
            </Typography>
            <Typography variant="h6" component="h6">
              Price : {singleDialog.Price}
            </Typography>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default Notification;
