

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaHandPointLeft } from "react-icons/fa";

import "./Table.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TableContainer,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useUserAuth } from "../../Context/UseAuthContext";
import { Link } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import logo from "../../imgs/profile.png";

function createData(name, trackingId, date, status) {
  return { name, trackingId, date, status };
}

const rows = [
  createData("Lasania Chiken Fri", 18908424, "2 March 2022", "Approved"),
  createData("Big Baza Bang ", 18908424, "2 March 2022", "Pending"),
  createData("Mouth Freshner", 18908424, "2 March 2022", "Approved"),
  createData("Cupcake", 18908421, "2 March 2022", "Delivered"),
];

const makeStyle = (status) => {
  if (status) {
    return {
      background: "rgb(145 254 159 / 47%)",
      color: "green",
    };
  } else {
    return {
      background: "#ffadad8f",
      color: "red",
    };
  }
};

const  ProjectTable=()=> {
  const {
    token,
    getSubadminsData,
    subadmins,
    verifiedSubadmin,
    showVerifaction,
    setShowVerificationstatus,
    singleSubadmin,
    handleSubadminVerifaction,

    FilterAuth,
    searchQuary,
    orderFilter,
    handleFilterAuthClick,
    handleSearchVendorNameChange,
    handleOrderFilter,
    handleSelectFilterChange,
  } = useUserAuth();

  useEffect(() => {
    if (token) {
      getSubadminsData();
    }
  }, [token, FilterAuth, searchQuary, orderFilter]);

  return (
   
         <div className="mycourses container">
        <div className="mycourse-content">
        <h1>Projects</h1>
          <p>Best Projects Uploaded By Vendor.</p>
        </div>
        <div className="mycourse-data">
        <div className="mycourses-button">
          <button
            style={{ backgroundColor: FilterAuth === "" ? "#140342" : "" }}
            onClick={() => handleFilterAuthClick("")}
          >
            All Vendor
          </button>
          <button
            style={{ backgroundColor: FilterAuth ? "#140342" : "" }}
            onClick={() => handleFilterAuthClick(true)}
          >
            Verified
          </button>
          <button
            style={{ backgroundColor: FilterAuth === false ? "#140342" : "" }}
            onClick={() => handleFilterAuthClick(false)}
          >
            Not Verified
          </button>
        </div>
          <div className="dropdown-containers">
            <div className="search-container">
              <input
                type="text"
                placeholder="Search Courses..."
                // value={searchQuery}
                onChange={handleSearchVendorNameChange}
              />
              <FiSearch className="mycourse-searchicon" />
            </div>

            <div className="category">
            <select value={orderFilter} onChange={handleOrderFilter}>
              <option value={-1}>Sort By Recent</option>
              <option value={1}>Sort By Previous</option>
            </select>
            </div>

            <div className="review">
            <select value={FilterAuth} onChange={handleSelectFilterChange}>
              <option value="">All Vendor</option>
              <option value={true}>Verified</option>
              <option value={false}>Not Verified</option>
            </select>
            </div>
           
          </div>
          <div className="mycourses-section1">
            {subadmins &&
              subadmins.map((course) => (
                <div key={course._id} className="mycourse-section-cards">
                  {course.profileUrl ? (
                    <img src={course.profileUrl} alt={course.name} />
                  ) : (
                    <img src={logo} alt={course.name} />
                  )}
                  <h4>{course.name}</h4>
                  <Link 
                  to={`/projects/${course._id}`}
                    className="mycourse-section-cards-a"
                   
                  >
                    All Project...
                  </Link>

                  <button
                    style={makeStyle(course.isVerified)}
                    className="mycourses-btn"
                    onClick={() => verifiedSubadmin(course)}
                  >
                    {/* <img src="https://cdn-icons-png.freepik.com/512/6928/6928921.png" width='2vw' height='10px' />   */}
                    {course.isVerified ? "verified" : "Not Verified"}
                  </button>
                </div>
              ))}
          </div>
          {/* <CourseList courses={filteredCourses} /> */}
        </div>
        <Dialog
        open={showVerifaction}
        onClose={() => setShowVerificationstatus(false)}
      >
        <DialogTitle> 
          Are You sure you want to give{" "}
          {singleSubadmin.isVerified ? "Not Verified" : "Verified"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowVerificationstatus(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubadminVerifaction}>
            {singleSubadmin.isVerified ? "Not Verified" : "Verified"}
          </Button>
        </DialogActions>
      </Dialog>
      </div>
     
    
    
  );
}
export default ProjectTable;
