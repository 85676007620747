import React from 'react'
import { useUserAuth } from '../Context/UseAuthContext'
import { Navigate } from 'react-router-dom';

const PrivateRoutes = ({children}) => {
    const {token}=useUserAuth();
    if(!token){
        return<Navigate to={"/login"} />;
     }
     return children;
}

export default PrivateRoutes