import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Input,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../Context/UseAuthContext";
import { MdOutlineDeleteSweep } from "react-icons/md";
import toast from "react-hot-toast";

const CreateCategory = () => {
  const {
    allMainCategory,
    getMainCategory,
    allSubCategory,
    getSubCategory,
    allCourseCategory,
    getCourseCategory,
    // getProjectCategory,
    darkThemesetter,
  } = useUserAuth();
  const [MainCategory, setMainCategory] = useState(null);
  const [SubCategory, setSubCategory] = useState(null);
  const [CourseCategory, setCourseCategory] = useState(null);
  const [ProjectCategory, setProjectCategory] = useState(null);

  const [mainCategoryIDToDelete, setMainCategoryToDelete] = useState(null);
  const [mainShowConfirm, setMainShowConfirmation] = useState(false);

  const [subCategoryIDToDelete, setSubCategoryToDelete] = useState(null);
  const [subShowConfirm, setSubShowConfirmation] = useState(false);

  const [courseCategoryIDToDelete, setCourseCategoryToDelete] = useState(null);
  const [courseShowConfirm, setCourseShowConfirmation] = useState(false);

  // const [projectCategoryIDToDelete, setProjectCategoryToDelete] =
  //   useState(null);
  // const [projectShowConfirm, setProjectShowConfirmation] = useState(false);

  const createCategoryTheme = darkThemesetter ? "dark-theme" : "light-theme";
  const createCategoryTextColor = darkThemesetter ? "dark-text" : "light-text";

  const handleMainCategorySubmit = () => {
    fetch("https://smartuter.com/api/category/main", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ category: MainCategory }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.msg == "Main Category saved successfully") {
          getMainCategory();
          toast.success(`${res.msg}`);
        } else {
          toast.error(`${res.msg}`);
        }
      })
      .catch((err) => console.log(err));
    setMainCategory("");
  };

  const handleMainConfirmDelete = () => {
    if (mainCategoryIDToDelete) {
      const categoryID = mainCategoryIDToDelete;
      fetch(`https://smartuter.com/api/category/main/${categoryID}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setMainCategory("");
          getMainCategory();
        })
        .catch((err) => console.log(err));
    }
    setMainShowConfirmation(false);
  };

  const handleSubCategorySubmit = () => {
    fetch("https://smartuter.com/api/category/sub", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ category: SubCategory }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.msg == "Sub Category saved successfully") {
          toast.success(`${res.msg}`);

          getSubCategory();
          setSubCategory("");
        } else {
          toast.error(`${res.msg}`);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSubConfirmDelete = () => {
    if (subCategoryIDToDelete) {
      const categoryID = subCategoryIDToDelete;
      fetch(`https://smartuter.com/api/category/sub/${categoryID}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setSubCategory("");
          getSubCategory();
        })
        .catch((err) => console.log(err));
    }
    setSubShowConfirmation(false);
  };

  const handleCourseCategorySubmit = () => {
    fetch("https://smartuter.com/api/category/course", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ category: CourseCategory }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.msg === "Course Category saved successfully") {
          getCourseCategory();
          setCourseCategory("");
          toast.success(`${res.msg}`);
        } else {
          toast.error(`${res.msg}`);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCourseConfirmDelete = () => {
    if (courseCategoryIDToDelete) {
      const categoryID = courseCategoryIDToDelete;
      fetch(`https://smartuter.com/api/category/course/${categoryID}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          getCourseCategory();
        })
        .catch((err) => console.log(err));
    }
    setCourseShowConfirmation(false);
  };

  const handleProjectCategorySubmit = () => {
    fetch("https://smartuter.com/api/category/project", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ category: ProjectCategory }),
    })
      .then((res) => res.json())
      .then((res) => {
        toast.success(`${res.msg}`);
        // getProjectCategory();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getMainCategory();
    getSubCategory();
    getCourseCategory();
    // getProjectCategory();
  }, []);

  return (
    <div className={`container  ${createCategoryTheme}`}>
      <h1 className={createCategoryTextColor}>Add Category</h1>
      <p>Welcome to your Category, Create your Category here..</p>

      <div className="Add-Category">
        <div className={`Categori ${createCategoryTheme}`}>
          <InputLabel className={createCategoryTextColor}>
            Create Main Category
          </InputLabel>
          <Input
            type="text"
            placeholder=" Main Category"
            className={createCategoryTheme}
            value={MainCategory}
            onChange={(e) => setMainCategory(e.target.value)}
          />
          <Button onClick={handleMainCategorySubmit}>Submit</Button>
        </div>
        <div className={`Categori ${createCategoryTheme} `}>
          <label className={createCategoryTextColor}>Create Sub Category</label>
          <Input
            type="text"
            placeholder=" Sub Category"
            value={SubCategory}
            className={createCategoryTheme}
            onChange={(e) => setSubCategory(e.target.value)}
          />
          <Button onClick={handleSubCategorySubmit}>Submit</Button>
        </div>
        {/* <div className={`Categori ${createCategoryTheme} `}>
          <label className={createCategoryTextColor}>
            Create Child Category
          </label>
          <Input
            type="text"
            placeholder=" Child Category"
            className={createCategoryTheme}
            value={CourseCategory}
            onChange={(e) => setCourseCategory(e.target.value)}
          />
          <Button onClick={handleCourseCategorySubmit}>Submit</Button>
        </div> */}
      </div>
      <div className="dispaly_category">
        <div className={`Category ${createCategoryTheme}`}>
          <h1 className={createCategoryTextColor}>Main Categories</h1>
          <div>
            {allMainCategory &&
              allMainCategory.map((e, i) => (
                <div key={i} className="category-item">
                  <h5 className={createCategoryTextColor}>{e.category}</h5>
                  <Button
                    onClick={() => {
                      setMainCategoryToDelete(e._id);
                      setMainShowConfirmation(true);
                    }}
                  >
                    <MdOutlineDeleteSweep className="cat-delete-btn" />
                  </Button>
                </div>
              ))}
          </div>
        </div>
        <div className={`Category ${createCategoryTheme}`}>
          <h1 className={createCategoryTextColor}>Sub Categories</h1>
          <div>
            {allSubCategory &&
              allSubCategory.map((e, i) => (
                <div key={i} className="category-item">
                  <h5 className={createCategoryTextColor}>{e.category}</h5>
                  <Button
                    onClick={() => {
                      setSubCategoryToDelete(e._id);
                      setSubShowConfirmation(true);
                    }}
                  >
                    <MdOutlineDeleteSweep className="cat-delete-btn" />
                  </Button>
                </div>
              ))}
          </div>
        </div>
        {/* <div className={`Category ${createCategoryTheme}`}>
          <h1 className={createCategoryTextColor}>Child Categories</h1>
          <div>
            {allCourseCategory &&
              allCourseCategory.map((e, i) => (
                <div key={i} className="category-item">
                  <h5 className={createCategoryTextColor}>{e.category}</h5>
                  <Button
                    onClick={() => {
                      setCourseCategoryToDelete(e._id);
                      setCourseShowConfirmation(true);
                    }}
                  >
                    <MdOutlineDeleteSweep className="cat-delete-btn" />
                  </Button>
                </div>
              ))}
          </div>
        </div> */}
      </div>

      {/* Section for showing form popup */}
      <Dialog
        open={mainShowConfirm}
        onClose={() => setMainShowConfirmation(false)}
      >
        <DialogTitle>Are you sure you want to delete this post ?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setMainShowConfirmation(false)}>Cancel</Button>
          <Button onClick={handleMainConfirmDelete}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={subShowConfirm}
        onClose={() => setSubShowConfirmation(false)}
      >
        <DialogTitle>Are you sure you want to delete this post ?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setSubShowConfirmation(false)}>Cancel</Button>
          <Button onClick={handleSubConfirmDelete}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={courseShowConfirm}
        onClose={() => setCourseShowConfirmation(false)}
      >
        <DialogTitle>Are you sure you want to delete this post ?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setCourseShowConfirmation(false)}>
            Cancel
          </Button>
          <Button onClick={handleCourseConfirmDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateCategory;
