import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tree from "react-d3-tree";
import "./Admintree.css";
import { useUserAuth } from "../../Context/UseAuthContext";

const AdminTree = () => {
  const { rootNotification, getNotification } = useUserAuth();
  const navigate = useNavigate();
  const [treeData, setTreeData] = useState({ name: "root", children: [] });
  const [loading, setLoading] = useState(true);
  const { darkThemesetter } = useUserAuth();
  const [count, setCount] = useState(0);
  const [highlightedNodes, setHighlightedNodes] = useState([]);

  const fetchTreeData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://smartuter.com/api/enduser/getTree",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: "" }),
        }
      );
      if (response.ok) {
        const result = await response.json();
        const data = result.userTree;
        setCount(result.userCount);
        const formattedData = buildTree(data);

        setTreeData({
          name: "root", // Update node name based on userId
          children: formattedData,
        });
        setLoading(false);
      } else {
        console.error("Failed to fetch tree data");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching tree data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTreeData();
  }, []);

  useEffect(() => {
    if (rootNotification && rootNotification.length > 0) {
      const newHighlightedNodes = new Set(highlightedNodes);

      rootNotification.forEach((rootId) => {
        newHighlightedNodes.add(rootId);
        setTimeout(() => {
          setHighlightedNodes((prev) => prev.filter((id) => id !== rootId));
          handleDeleteReferralIds([rootId]);
          getNotification();
        }, 1 * 60 * 1000); // 1 minute in milliseconds
      });

      setHighlightedNodes(Array.from(newHighlightedNodes));
    }
  }, [rootNotification]);

  const handleDeleteReferralIds = async (idsToDelete) => {
    try {
      const response = await fetch(
        "https://smartuter.com/api/notification/deleterootuserreferralIds",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ rootIds: idsToDelete }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete referral IDs");
      }

      const data = await response.json();
    } catch (error) {
      console.error("Error deleting referral IDs:", error.message);
    }
  };

  const buildTree = (users) => {
    // Create intermediary "level" nodes for every two children
    const createLevelNodes = (nodes) => {
      const levelNodes = [];
      for (let i = 0; i < nodes.length; i += 2) {
        const children = nodes.slice(i, i + 2);
        levelNodes.push({
          name: `level${i / 2 + 1}`,
          children: children,
        });
      }
      return levelNodes;
    };

    return createLevelNodes(
      users.map((user) => ({
        name: (user.name.toLowerCase().substring(0,7)+"::"+(user.referralCount?user.referralCount:0)),

        attributes: {
          id: user._id,
          email: user.email,
        },
        children: [], // Initially no children
      }))
    );
  };

  const handleNodeClick = async (nodeDatum) => {
    const userId = nodeDatum && nodeDatum.attributes && nodeDatum.attributes.id;
    const name = nodeDatum.name;
    await fetchTreeData();
    navigate(`/allendusers/${userId}/${name}`);
  };

  const customNode = ({ nodeDatum }) => {
    console.log(nodeDatum);
    // Define colors based on node attributes or other criteria
    const isHighlighted = highlightedNodes.includes(
      nodeDatum && nodeDatum.attributes && nodeDatum.attributes.id
    );

    const getColor = (node) =>
      isHighlighted
        ? "green"
        : node.attributes && node.attributes.id
        ? "darkblue"
        : "gray";

    const nodeColor = getColor(nodeDatum);
    const dummyImageURL =
      "https://cdn.pixabay.com/photo/2015/03/04/22/35/head-659651_960_720.png"; // URL or path to the dummy image
    const isTopNode = nodeDatum.name === treeData.name;

    return (
      <g onClick={() => handleNodeClick(nodeDatum)}>
        <circle r="15" fill={nodeColor} />
        <image href={dummyImageURL} x="-15" y="-15" width="30" height="30" />
        <text fill="black" fontWeight={"normal"} strokeWidth="1" x="20" y="5">
          {nodeDatum.name}
        </text>
        {isTopNode && (
          <text fill="black" fontWeight="normal" x="20" y="25">
            Referrals: {count}
          </text>
        )}
      </g>
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  const textColor = darkThemesetter ? "dark-text" : "light-text";

  return (
    <div>
      <div className="SectionDashBoardVendorAnalyticsHeader">
        <h1 className={textColor}>Referral System</h1>
        <p>Welcome to your Admin, Represented your Admin References</p>
      </div>
      <div id="treeWrapper" style={{ width: "100%", height: "100vh" }}>
        <button className="refer-button">No of Referrals:{count}</button>

        <Tree
          svgClassName="link__path"
          data={treeData}
          renderCustomNodeElement={customNode}
          orientation="vertical" // Set the tree orientation to vertical
          pathFunc="elbow" // Use an elbow path for connections
          separation={{ siblings: 1, nonSiblings: 1 }} // Adjust node spacing
          translate={{ x: 100, y: 50 }} // Set initial position of the tree
          zoomable={true} // Enable zooming
          collapsible={true} // Enable node collapsing
        />
      </div>
    </div>
  );
};

export default AdminTree;
