import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const navigate = useNavigate();
  const token = localStorage.getItem("admintoken");
  const [socket, updateSocket] = useState("");

  const [user, setUser] = useState("");
  const [allMainCategory, setMainCategoryes] = useState(null);
  const [allSubCategory, setSubCategoryes] = useState(null);
  const [allCourseCategory, setCourseCategoryes] = useState(null);
  const [allProjectCategory, setProjectCategoryes] = useState(null);
  //VENDORS FILTE AN SEARCH STATES
  const [FilterAuth, setFilterAuth] = useState("");
  const [searchQuary, setSearchQuary] = useState("");
  const [orderFilter, setOrderFilte] = useState(-1);
  //VENDOR
  const [subadmins, setSubadmins] = useState("");
  const [singleSubadmin, setsingelSubadmin] = useState("");

  const [showVerifaction, setShowVerificationstatus] = useState(false);

  const [loading, setLoadingl] = useState(false);
  //Notifications
  const [notification, setNotification] = useState(null);
  const [referralNotification, setReferralNotification] = useState(null);
  const [rootNotification, setRootNotification] = useState(null);

  const [darkThemesetter, setDarkTheme] = useState(false);
  // const [copyText, setCopyText] = useState(null)

  const darkTheme = () => {
    setDarkTheme(!darkThemesetter);
  };

  const getUserData = () => {
    setLoadingl(true);
    fetch("https://smartuter.com/api/admin/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setUser(res);
        setLoadingl(false);
      })

      .catch((err) => {
        localStorage.removeItem("admintoken");
        navigate("/login");
        setLoadingl(false);
      });
  };
  const getMainCategory = () => {
    fetch("https://smartuter.com/api/category/main", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        setMainCategoryes(res);
      })
      .catch((err) => console.log(err));
  };
  const getSubCategory = () => {
    fetch("https://smartuter.com/api/category/sub", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        setSubCategoryes(res);
      })
      .catch((err) => console.log(err));
  };
  const getCourseCategory = () => {
    fetch("https://smartuter.com/api/category/course", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        setCourseCategoryes(res);
      })
      .catch((err) => console.log(err));
  };
  const getSubadminsData = () => {
    fetch(
      `https://smartuter.com/api/all-sub-admins?filter=${FilterAuth}&search=${searchQuary}&sortOrder=${orderFilter}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setSubadmins(res);
      })
      .catch((err) => console.log(err));
  };

  const getSingleSubadminData = async (subadminID) => {
    try {
      const res = await axios.get(
        `https://smartuter.com/api/all-sub-admins/${subadminID}`
      );
      setsingelSubadmin(res.data.subadmin);
    } catch (error) {
      console.log(error.message);
    }
  };
  //VENDOR FUNCANALITY

  const verifiedSubadmin = (e) => {
    setsingelSubadmin(e);
    setShowVerificationstatus(true);
  };

  const handleSubadminVerifaction = () => {
    const subadminID = singleSubadmin._id;
    const payload = { isVerified: singleSubadmin.isVerified ? false : true };
    fetch(
      `https://smartuter.com/api/all-sub-admins/authenticate/${subadminID}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        getSubadminsData();
        setShowVerificationstatus(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFilterAuthClick = (data) => {
    setFilterAuth(data);
  };

  // Function to handle the selection change
  const handleSelectFilterChange = (event) => {
    setFilterAuth(event.target.value);
  };
  //Function Handle Search Changes
  const handleSearchVendorNameChange = (e) => {
    setSearchQuary(e.target.value);
  };
  //Function  Handle By ORDER
  const handleOrderFilter = (e) => {
    setOrderFilte(e.target.value);
  };
  //Notifictions
  const getNotification = async () => {
    try {
      const res = await axios.get(
        "https://smartuter.com/api/notification/admin/"
      );
      //  const audio=new Audio(notifay);
      //  audio.play();
      setNotification(res.data.getNotificationData);
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchReferralNotifications = async () => {
    try {
      const response = await axios.get(
        "https://smartuter.com/api/notification/getreferralnotification"
      );
      // const referralNotificationArray = []
      const referralNotifyArray = response.data.getNotificationData.map(
        (item) => item.data.referralId
      );
      // );
      setReferralNotification(referralNotifyArray);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      return null;
    }
  };

  const fetchRootReferralNotifications = async () => {
    try {
      const response = await axios.get(
        "https://smartuter.com/api/notification/getrootuserreferralnotificationcontroller"
      );
      const rootNotifyArray = response.data.getNotificationData.map(
        (item) => item.data.rootUser
      );
      setRootNotification(rootNotifyArray);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      return null;
    }
  };

  useEffect(() => {
    if (token) {
      // socket.on("dataChange", (change) => {
      fetchRootReferralNotifications();
      getUserData();
      getMainCategory();
      getSubCategory();
      getCourseCategory();
      // getProjectCategory();
      // getNotification();
      // });
      // Define the timeout duration (24 hours in milliseconds)
      const timeoutDuration = 24 * 60 * 60 * 1000; // 24 hours

      // Set a timeout to remove the item after 24 hours
      const timeoutId = setTimeout(() => {
        // Remove item from local storage
        localStorage.removeItem("admintoken");
      }, timeoutDuration);

      // Clean up the timeout to prevent memory leaks
      return () => clearTimeout(timeoutId);
    }

    // return () => socket.disconnect();
  }, [token]);
  useEffect(() => {
    if (token) {
      getSubadminsData();

      getNotification();
      fetchReferralNotifications();
    }
  }, [token, socket]);

  // Sree copy clipboard UPI
  const copyToclip = (copyText) => {
    navigator.clipboard.writeText(copyText).then(
      () => {
        toast.success("Referral link copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  return (
    <userAuthContext.Provider
      value={{
        copyToclip,
        user,
        setUser,
        getUserData,
        token,
        allMainCategory,
        setMainCategoryes,
        getMainCategory,
        allSubCategory,
        getSubCategory,
        allCourseCategory,
        getCourseCategory,
        allProjectCategory,
        // getProjectCategory,
        socket,
        updateSocket,

        //VENDOR SECTION
        getSubadminsData,
        subadmins,
        getSingleSubadminData,
        verifiedSubadmin,
        showVerifaction,
        setShowVerificationstatus,
        singleSubadmin,
        handleSubadminVerifaction,

        FilterAuth,
        searchQuary,
        orderFilter,
        handleFilterAuthClick,
        handleSearchVendorNameChange,
        handleOrderFilter,
        handleSelectFilterChange,
        loading,
        setLoadingl,
        //Notification
        notification,
        getNotification,
        referralNotification,

        fetchReferralNotifications,
        //Them
        rootNotification,
        fetchRootReferralNotifications,

        darkTheme,
        darkThemesetter,

        navigate,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
