import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../../Context/UseAuthContext";
import { useParams } from "react-router-dom";
import profie from "../../../imgs/profile.png"
import Posts from "../PostsSingeadmin/Posts";
import Projects from "../ProjectsSingleadmin/Projects";
import StudyMaterials from "../StudyMaterialsSingleadmin/StudyMaterials";
// import { FaUserLarge } from "react-icons/pi";
import "./subadminDetails.css";
import { FaUserLarge } from "react-icons/fa6";
import { MdMarkEmailRead } from "react-icons/md";
import { MdLocalPhone } from "react-icons/md";
import { PiCopyThin } from "react-icons/pi";
const SubadminDetails = () => {
  const { userID } = useParams();

  const { getSingleSubadminData, singleSubadmin,copyToclip } =
    useUserAuth();
  const [showData, setDatashow] = useState(1);
  const handleShowData = (e) => {
    setDatashow(e);
  };

  
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  useEffect(() => {
    getSingleSubadminData(userID);
  }, []);
  return (
    <div className="subadmin-main-container">
      <div className="subadmin-sub-container">
        {/* <h4>{singleSubadmin.name}</h4> */}
        <div className="single-end-user-container">
          {singleSubadmin && (
            <div className="main-user-details">
              <div className="user-details">
                <div>
                  <img
                    src={singleSubadmin.profileUrl}
                    alt="Profile"
                    className="avatar"
                    onError={(e) => {
                      e.target.src = profie;
                    }}
                  />
                </div>
                <div className="end-detail-container">
                  <div className="enduser-detailss">
                    <p>
                      <FaUserLarge />
                    </p>
                    <p>
                      <MdMarkEmailRead />
                    </p>
                    <p>
                      <MdLocalPhone />
                    </p>
                  </div>
                  <div className="enduser-detailss">
                    <p>
                      {truncateText(singleSubadmin.name, 20)}
                    </p>
                    <p>{singleSubadmin.email}</p>
                    <p>{singleSubadmin.number}</p>
                  </div>
                </div>{" "}
              </div>{" "}
              {/* <div className="enduser-wallet">
                <h1>
                  <IoWalletOutline className="wallet-icon" />
                </h1>
                <div className="enduser-wallet-monet">
                  <p>Money: 2000/-</p>
                </div>
              </div> */}
            </div>
          )}

          <div className="subadmin-con-btn">
            <button   className={`subadmin-btn ${showData === 1 ? 'active' : ''}`} onClick={() => handleShowData(1)}>
              COURSE
            </button>
            <button  className={`subadmin-btn ${showData === 2 ? 'active' : ''}`} onClick={() => handleShowData(2)}>
              PROJECT
            </button>
            <button  className={`subadmin-btn ${showData === 3 ? 'active' : ''}`} onClick={() => handleShowData(3)}>
              STUDYMATERIAL
            </button>
            <button  className={`subadmin-btn ${showData === 4 ? 'active' : ''}`} onClick={() => handleShowData(4)}>
              BANKDETAILS
            </button>
            <button  className={`subadmin-btn ${showData === 5 ? 'active' : ''}`} onClick={() => handleShowData(5)}>
              UPIID
            </button>
            {showData === 1 && <Posts />}
            {showData === 2 && <Projects />}
            {showData === 3 && <StudyMaterials />}
            {showData === 4 && singleSubadmin && (
              <div className="account-details">
                {/* <h3>Bank Details</h3> */}
                <ul className="singleenduser-bankdetails-main">
                  {singleSubadmin.bankDetails.map((bank, index) => (
                    <li className="singleenduser-bankdetails" key={index}>
                      <span>Account No</span>
                      <p>{bank.accountNumber}</p>
                      <span>Bank Name</span>
                      <p>{bank.bankName}</p>
                      <span>IFSC Code</span>
                      <p> {bank.ifscCode}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {showData === 5 && singleSubadmin && (
              <div className="upi-details">
                {/* <h3>UPI Details</h3> */}
                <ul className="upi-details-main">
                  {singleSubadmin.upi.map((upiDetail, index) => (
                    <li className="singleenduser-upi-details" key={index}>
                      <div onClick={() => copyToclip(upiDetail.upiid)}>
                        <span>UPI ID</span>
                        {/* <p > </p> */}
                        <p className="enuser-text">
                          <li>{upiDetail.upiid}</li>
                          <li>
                            <PiCopyThin />
                          </li>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubadminDetails;
