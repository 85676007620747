import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./SingleEndUser.css";
import SingleEndUserTree from "./SingleEndUserTree";
import { FaUserLarge } from "react-icons/fa6";
import { MdMarkEmailRead } from "react-icons/md";
import { MdLocalPhone } from "react-icons/md";

import { PiCopyThin } from "react-icons/pi";
import { useUserAuth } from "../../Context/UseAuthContext";
import { IoWalletOutline } from "react-icons/io5";

const SingleEndUser = () => {
  const { id } = useParams();
  const { copyToclip, darkThemesetter } = useUserAuth();
  const [user, setUser] = useState(null);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("tree");

  const singleEnduserTheme = darkThemesetter ? "dark-theme" : "light-theme";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://smartuter.com/api/admin/getallendusers/${id}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        const data = await response.json();
        setUser(data.getSingleUser);
        setOrders(data.getSingleUser.orders);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);
  console.log(user,"LINE 45 AT singleEndUser")
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className={`single-end-user-container ${singleEnduserTheme}`}>
      {user && (
        <div className="main-user-details">
          <div className="user-details">
            <div>
              <img
                src={user.profileUrl}
                alt="Profile"
                className="avatar"
                onError={(e) => {
                  e.target.src =
                    "https://www.timeoutdubai.com/cloud/timeoutdubai/2021/09/11/hfpqyV7B-IMG-Dubai-UAE-1200x800.jpg";
                }}
              />
            </div>
            <div className="end-detail-container">
              <div className="enduser-detailss">
                <p>
                  <FaUserLarge />
                </p>
                <p>
                  <MdMarkEmailRead />
                </p>
                <p>
                  <MdLocalPhone />
                </p>
              </div>
              <div className="enduser-detailss">
                <p>{user.name}</p>
                <p>{user.email}</p>
                <p>{user.number}</p>
              </div>
            </div>{" "}
          </div>

          {/* <div className="enduser-wallet">
            <h1>
              <IoWalletOutline className="wallet-icon" />
            </h1>
            <div className="enduser-wallet-monet">
              <p>Money: 2000/-</p>
            </div>
          </div> */}
        </div>
      )}

      <div className="tab-buttons">
        <button
          className={activeTab === "order" ? "active" : ""}
          onClick={() => setActiveTab("order")}
        >
          Orders
        </button>
        <button
          className={activeTab === "tree" ? "active" : ""}
          onClick={() => setActiveTab("tree")}
        >
          Tree
        </button>
        <button
          className={activeTab === "account" ? "active" : ""}
          onClick={() => setActiveTab("account")}
        >
          Account Detail
        </button>
        <button
          className={activeTab === "upi" ? "active" : ""}
          onClick={() => setActiveTab("upi")}
        >
          UPI
        </button>
      </div>

      {activeTab === "order" &&
        (orders && orders.length > 0 ? (
          <div className="orders">
            <ul>
              {orders.map((order) => (
                <li key={order.ID}>
                  <img
                    src={order.imageUrl}
                    alt="Order"
                    className="orderImageUrl"
                  />
                  <p>{order.postName}</p>
                  <p>Price: {order.Price}</p>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="no-course-found-container">
            <img
              src="https://res.cloudinary.com/dyqpzx80s/image/upload/v1713333642/no-course-found_ifervi.jpg"
              className="no-course-found-img"
              alt="no-course"
            />
            <p className="no-course-found-text">No Orders found.</p>
          </div>
        ))}

      {activeTab === "tree" && (
        <div className="single-end-user-tree">
          <SingleEndUserTree referralCount={user&&user.referralCount}/>
        </div>
      )}

      {activeTab === "account" && (
        <div className="account-details">
          {/* <h3>Bank Details</h3> */}
          <ul className="singleenduser-bankdetails-main">
            {user.bankDetails.map((bank, index) => (
              <li className="singleenduser-bankdetails" key={index}>
                <span>Account No</span>
                <p>{bank.accountNumber}</p>
                <span>Bank Name</span>
                <p>{bank.bankName}</p>
                <span>IFSC Code</span>
                <p> {bank.ifscCode}</p>
              </li>
            ))}
          </ul>
        </div>
      )}

      {activeTab === "upi" && (
        <div className="upi-details">
          {/* <h3>UPI Details</h3> */}
          <ul className="upi-details-main">
            {user.upi.map((upiDetail, index) => (
              <li className="singleenduser-upi-details" key={index}>
                <div onClick={() => copyToclip(upiDetail.upiid)}>
                  <span>UPI ID</span>
                  {/* <p > </p> */}
                  <p className="enuser-text">
                    <li>{upiDetail.upiid}</li>
                    <li>
                      <PiCopyThin />
                    </li>
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SingleEndUser;
