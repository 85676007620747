import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  Box,
  IconButton,
  Collapse,
  DialogTitle,
  DialogActions,
  Button,
  Dialog,
  TextField,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import "./Payment.css";
import { useUserAuth } from "../../Context/UseAuthContext";
import toast, { Toaster } from "react-hot-toast";

export const Payment = () => {
  const navigate = useNavigate();

  const [salesData, setSalesData] = useState([]);
  const [order_id, setOrderId] = useState("");
  const [username, setUsername] = useState("");
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [page, setPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);
  const [open, setOpen] = useState({});
  const [transactionId, setTransactionId] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [selectedSaleId, setSelectedSaleId] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [dialogType, setDialogType] = useState("");
  const { darkThemesetter } = useUserAuth();
  const [count, setCount] = useState(0);

  const mainBackgroundColor = darkThemesetter
    ? "transaction-container-dark-theme"
    : "transaction-container";
  const transactionSubContainer = darkThemesetter
    ? "transaction-sub-container-dark-theme"
    : "transaction-sub-container-light-theme";
  const textColor = darkThemesetter ? "dark-text" : "light-text";

  const handleOrderIdChange = (e) => setOrderId(e.target.value);
  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handleStartDateChange = (e) => setStartDate(e.target.value);
  const handleEndDateChange = (e) => setEndDate(e.target.value);

  useEffect(() => {
    fetchSalesData(page);
  }, [order_id, username, start_date, end_date, page]);

  const fetchSalesData = async (page) => {
    try {
      const response = await fetch(
        `https://smartuter.com/api/enduser/salesonplatform?page=${page}&username=${username}&order_id=${order_id}&start_date=${start_date}&end_date=${end_date}`
      );
      let data = await response.json();

      setSalesData(data.data);

      setTotalPages((prv) => prv + 1); // Example total pages
    } catch (error) {
      console.error("Error fetching sales data:", error);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    setCount((value - 1) * 19);
  };

  const handleRowClick = (id) => {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  const openDialog = (saleId, itemId, type) => {
    setSelectedSaleId(saleId);
    setSelectedItemId(itemId);
    setDialogType(type);
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
    setTransactionId("");
  };

  const handleDialogOk = async () => {
    if (dialogType === "vendor") {
      await toggleVendorPaymentStatus(selectedSaleId, selectedItemId);
    } else if (dialogType === "referralUser") {
      await toggleReferralUserPaymentStatus(selectedSaleId, selectedItemId);
    }
    closeDialog();
  };

  const toggleVendorPaymentStatus = async (saleId, itemId) => {
    try {
      const sale = salesData.find((s) => s._id === saleId);
      const item = sale.totalSaleItems.find((i) => i._id === itemId);

      const response = await fetch(
        "https://smartuter.com/api/enduser/togglevendorpaymentstatus",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orderId: sale.orderId,
            courseId: item.courseId,
            vendorId: item.vendorId,
            vendorPaymentId: transactionId,
          }),
        }
      );

      if (response.ok) {
        toast.success("Success: Vendor payment status updated Successfully");
        fetchSalesData(page);
      } else {
        toast.error(`Error:Vendor payment status Failed to Update`);
      }
    } catch (error) {
      toast.error(`Network error: ${error.message}`);
    }
  };

  const toggleReferralUserPaymentStatus = async (saleId, itemId) => {
    try {
      const sale = salesData.find((s) => s._id === saleId);
      const item = sale.totalSaleItems.find((i) => i._id === itemId);

      const response = await fetch(
        "https://smartuter.com/api/enduser/togglereferraluserpaymentstatus",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orderId: sale.orderId,
            courseId: item.courseId,
            referralUser: item.referralUser,
            referralUserPaymentId: transactionId.toLowerCase(),
          }),
        }
      );

      if (response.ok) {
        toast.success(
          "Success: Referral user payment status updated Successfully"
        );
        fetchSalesData(page);
      } else {
        const errorData = await response.json();
        toast.error(`Error: ${JSON.stringify(errorData)}`);
      }
    } catch (error) {
      toast.error(`Network error: ${error.message}`);
    }
  };

  return (
    <div className="admin-transaction-container">
      <div>
        <div className="admin-header-container">
          <h1 className={textColor}>Payments</h1>
          <p>Welcome to your Payments, Represented your Payments data.</p>
        </div>
        <div className="controls">
          <input
            type="text"
            placeholder="Order ID"
            value={order_id}
            onChange={handleOrderIdChange}
            className="order-id-input"
          />
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={handleUsernameChange}
            className="username-input"
          />
          <input
            type="date"
            value={start_date}
            onChange={handleStartDateChange}
            className="start-date-input"
          />
          <input
            type="date"
            value={end_date}
            onChange={handleEndDateChange}
            className="end-date-input"
          />
        </div>

        <Container className={`transactionsContainer ${mainBackgroundColor}`}>
          <Typography variant="h4" gutterBottom></Typography>
          <TableContainer component={Paper} className={transactionSubContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="tableHeadCell" />
                  <TableCell className={`tableHeadCell ${textColor}`}>
                    S.NO
                  </TableCell>
                  <TableCell className={`tableHeadCell ${textColor}`}>
                    Sale Date
                  </TableCell>

                  <TableCell className={`tableHeadCell ${textColor}`}>
                    Order ID
                  </TableCell>
                  <TableCell className={`tableHeadCell ${textColor}`}>
                    User name
                  </TableCell>
                  <TableCell className={`tableHeadCell ${textColor}`}>
                    Payment ID
                  </TableCell>
                  <TableCell className={`tableHeadCell ${textColor}`}>
                    Amount
                  </TableCell>
                  {/* <TableCell className={`tableHeadCell ${textColor}`}>
                    Sale Date
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(salesData) &&
                  salesData.map((sale, index) => (
                    <React.Fragment key={sale._id}>
                      <TableRow className="tableRowSpacing">
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            className={textColor}
                            onClick={() => handleRowClick(sale._id)}
                          >
                            {open[sale._id] ? (
                              <KeyboardArrowUp />
                            ) : (
                              <KeyboardArrowDown />
                            )}
                          </IconButton>
                        </TableCell>

                        <TableCell className={textColor}>
                          {index + 1 + count}
                        </TableCell>

                        <TableCell className={textColor}>
                          {new Date(sale.saleDate).toLocaleString()}
                        </TableCell>
                        <TableCell className={textColor}>
                          {sale.orderId}
                        </TableCell>
                        <TableCell className={textColor}>
                          {sale.userName}
                        </TableCell>
                        <TableCell className={textColor}>
                          {sale.paymentId}
                        </TableCell>
                        <TableCell className={textColor}>
                          {sale.totalSaleAmount}
                        </TableCell>
                        {/* <TableCell className={textColor}>
                          {new Date(sale.saleDate).toLocaleString()}
                        </TableCell> */}
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={7}
                        >
                          <Collapse
                            in={open[sale._id]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box className="collapseBox">
                              <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                                className={textColor}
                              >
                                Sale Items
                              </Typography>
                              <Table size="small" aria-label="sale-items">
                                <TableHead>
                                  <TableRow>
                                    <TableCell className={textColor}>
                                      Course name
                                    </TableCell>
                                    <TableCell className={textColor}>
                                      Price
                                    </TableCell>
                                    <TableCell className={textColor}>
                                      Vendor ID
                                    </TableCell>
                                    <TableCell className={textColor}>
                                      Vendor name
                                    </TableCell>
                                    <TableCell className={textColor}>
                                      Platform Fee
                                    </TableCell>
                                    <TableCell className={textColor}>
                                      Vendor Amount
                                    </TableCell>
                                    <TableCell className={textColor}>
                                      Vendor Payment Status
                                    </TableCell>
                                    <TableCell className={textColor}>
                                      Referral Amount
                                    </TableCell>
                                    <TableCell className={textColor}>
                                      Referral Payment Status
                                    </TableCell>
                                    <TableCell className={textColor}>
                                      Reffered user
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {sale.totalSaleItems ? (
                                    sale.totalSaleItems.map((item) => (
                                      <TableRow key={item ? item._id : "empty"}>
                                        {item ? (
                                          <>
                                            <TableCell className={textColor}>
                                              {item.courseName}
                                            </TableCell>
                                            <TableCell className={textColor}>
                                              {item.price}
                                            </TableCell>
                                            <TableCell
                                              className={textColor}
                                              onClick={() =>
                                                navigate(
                                                  `/subadmindetails/${item.vendorId}`
                                                )
                                              }
                                            >
                                              {item.vendorId}
                                            </TableCell>
                                            <TableCell className={textColor}>
                                              {item.vendorName}
                                            </TableCell>
                                            <TableCell className={textColor}>
                                              {item.platformFee}
                                            </TableCell>
                                            <TableCell className={textColor}>
                                              {item.vendorAmount}
                                            </TableCell>
                                            <TableCell
                                              className={
                                                item.vendorPaymentStatus
                                                  ? "paymentStatusApproved"
                                                  : "paymentStatusPending"
                                              }
                                              onClick={() =>
                                                openDialog(
                                                  sale._id,
                                                  item._id,
                                                  "vendor"
                                                )
                                              }
                                            >
                                              {item.vendorPaymentStatus
                                                ? item.vendorPaymentId
                                                : "Pending"}
                                            </TableCell>
                                            <TableCell className={textColor}>
                                              {item.referralAmount
                                                ? item.referralAmount
                                                : 0}
                                            </TableCell>
                                            {item.referralUser ? (
                                              <TableCell
                                                className={
                                                  item.referralUserPaymentStatus
                                                    ? "paymentStatusApproved"
                                                    : "paymentStatusPending"
                                                }
                                                onClick={() =>
                                                  openDialog(
                                                    sale._id,
                                                    item._id,
                                                    "referralUser"
                                                  )
                                                }
                                              >
                                                {item.referralUserPaymentStatus
                                                  ? item.referralUserPaymentId
                                                  : "Pending"}
                                              </TableCell>
                                            ) : (
                                              <TableCell className={textColor}>
                                                No referred user
                                              </TableCell>
                                            )}

                                            {item.referralUser ? (
                                              <TableCell
                                                className={
                                                  item.referralUserPaymentStatus
                                                    ? "paymentStatusApproved"
                                                    : "paymentStatusPending"
                                                }
                                                onClick={() =>
                                                  navigate(
                                                    `/allendusers/${item.referralUser}/${item.referralUserName}`
                                                  )
                                                }
                                              >
                                                {`${item.referralUserName}`}
                                              </TableCell>
                                            ) : (
                                              <TableCell className={textColor}>
                                                No referred user
                                              </TableCell>
                                            )}
                                          </>
                                        ) : (
                                          <TableCell colSpan={7}>
                                            No item available
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    ))
                                  ) : (
                                    <TableRow>
                                      <TableCell colSpan={7}>
                                        No items available
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="center" my={2} p={2}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
            />
          </Box>
        </Container>
      </div>
      <Dialog open={showDialog} onClose={closeDialog}>
        <Toaster />
        <DialogTitle>Enter Transaction ID</DialogTitle>
        <Box p={3}>
          <TextField
            fullWidth
            value={transactionId}
            onChange={(e) => setTransactionId(e.target.value)}
            label="Transaction ID"
          />
        </Box>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={handleDialogOk}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
