import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import Logo from "../imgs/logo.png";
import { UilSignOutAlt } from "@iconscout/react-unicons";
// import { SidebarData } from "../Data/Data";
import { UilBars } from "@iconscout/react-unicons";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../Context/UseAuthContext";
import {
  UilEstate,
  UilUsersAlt,
  UilPackage,
  UilChart,
  UilApps,
  UilBorderClear,
  UilRupeeSign,
  UilShareAlt,
  UilAngleUp,
  UilBookReader,
  UilDesktopAlt ,
  UilBooks,
  UilLeftIndentAlt,
  
} from "@iconscout/react-unicons";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
const Sidebar = () => {
  const navigate = useNavigate();
  const { setUser, user, darkThemesetter } = useUserAuth();
  const [selected, setSelected] = useState(0);

  const [expanded, setExpaned] = useState(true);

  // Dropdown Implementation
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const sidebarTheme = darkThemesetter ? "dark-theme" : "sidebar-light-theme";
  const sidebarTextColor = darkThemesetter ? "dark-text" : "light-text";

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const sidebarVariants = {
    true: {
      left: "0",
    },
    false: {
      left: "-60%",
    },
  };
  const SidebarData = [
    {
      icon: UilEstate,
      heading: "Dashboard",
      path: "/",
    },
    // {
    //   icon: UilClipboardAlt,
    //   heading: "User",
    //   path: "/user",
    // },
    {
      icon: UilUsersAlt,
      heading: "Courses",
      path: "/courses",
    },
    {
      icon: UilUsersAlt,
      heading: "Projects",
      path: "/projects",
    },
    {
      icon: UilUsersAlt,
      heading: "Studymaterila",
      path: "/studymaterilas",
    },
    {
      icon: UilPackage,
      heading: "Referral",
      path: "/referral",
    }, //murali
    {
      icon: UilPackage,
      heading: "Payment",
      path: "/payment",
    },
    {
      icon: UilApps,
      heading: "Category+",
      path: "/create_category",
    },
  ];
  if (!user) {
    SidebarData.push({
      icon: UilChart,
      heading: "Login",
      path: "/login",
    });
  }
  const handlClickLink = (a, link) => {
    localStorage.setItem("a", a);
    setExpaned(false);
    setSelected(a);
    navigate(link);
  };

  useEffect(() => {
    // Retrieve the active tab from localStorage when the component mounts
    const savedTab = localStorage.getItem("a");
    if (savedTab) {
      setSelected(savedTab);
    }
  }, [selected]);

  const [isAllSourse, setIsAllSourse] = useState(false);

  const allSourse = () => {
    setIsAllSourse(!isAllSourse);
  };
  return (
    <>
      <div
        className="bars"
        style={{
          left: "2%",
          top: "1.5%",
          background: expanded ? "#e3e9ef" : "white",
        }}
        onClick={() => setExpaned(!expanded)}
      >
        <UilBars />
      </div>
      <motion.div
        className={`admin-sidebar ${sidebarTheme}`}
        variants={sidebarVariants}
        animate={window.innerWidth <= 768 ? `${expanded}` : ""}
      >
        {/* logo */}
        {/* <div className="logo">
        <img src={Logo} alt="logo" />
        <span>
          Sh<span>o</span>ps
        </span>
      </div> */}

        <div className="menu">
          <div
            className={selected === "home" ? "menuItem active" : "menuItem"}
            onClick={() => handlClickLink("home", "/")}
          >
            <UilEstate />

            <span>Dashboard</span>
          </div>

          <div
            className={
              selected === "All_sourse's" ? "menuItem active" : "menuItem"
            }
            onClick={allSourse}
          >
            <UilLeftIndentAlt />

            <span>All Source</span>
          
            <span
              className={`arrow-icon ${
                isAllSourse ? "rotate-up" : "rotate-down"
              }`}
            >
              <UilAngleUp />
            </span>
          </div>
          {/* Dropdown Items */}
          {isAllSourse && (
            <div className="dropdown-menu">
              <div
                className={
                  selected === "course" ? "menuItem active" : "menuItem"
                }
                onClick={() => handlClickLink("course", "/all-courses")}
              >
                <UilBookReader/>
                <span>Courses</span>
              </div>

              <div
                className={
                  selected === "project" ? "menuItem active" : "menuItem"
                }
                onClick={() => handlClickLink("project", "/all-projects")}
              >
                <UilDesktopAlt />
                <span>Projects</span>
              </div>

              <div
              style={{marginTop:"auto"}}
                className={
                  selected === "studymaterials" ? "menuItem active" : "menuItem"
                }
                onClick={() =>
                  handlClickLink("studymaterials", "/all-studymaterials")
                }
              >
                <UilBooks/>
                <span>Study Materials</span>
              </div>
            </div>
          )}

          <div
            className={
              selected === "studymaterial" ? "menuItem active" : "menuItem"
            }
            onClick={() => handlClickLink("studymaterial", "/vendor")}
          >
            <UilUsersAlt />

            <span>Vendor</span>
          </div>

          <div
            className={selected === "enduser" ? "menuItem active" : "menuItem"}
            onClick={() => handlClickLink("enduser", "/allendusers")}
          >
            <UilUsersAlt />

            <span>EndUsers</span>
          </div>

          <div
            className={
              selected === "create_category" ? "menuItem active" : "menuItem"
            }
            onClick={() =>
              handlClickLink("create_category", "/create_category")
            }
          >
            <UilApps />

            <span>Category+</span>
          </div>

          <div
            className={selected === "payment" ? "menuItem active" : "menuItem"}
            onClick={() => handlClickLink("payment", "/payment")}
          >
            <UilRupeeSign />

            <span>Payment</span>
          </div>

          <div
            className={selected === "tree" ? "menuItem active" : "menuItem"}
            onClick={() => handlClickLink("tree", "/referral")}
          >
            <UilShareAlt />

            <span>Referral</span>
          </div>

          {user && (
            <div
              className="menuItem"
              onClick={() => {
                localStorage.clear();
                navigate("/login");
                setUser("");
              }}
            >
              <UilSignOutAlt
                onClick={() => {
                  localStorage.clear();
                  navigate("/login");
                  setUser("");
                }}
              />
              <span>Logout</span>
            </div>
          )}
        </div>
      </motion.div>
    </>
  );
};

export default Sidebar;
