import React from 'react'
import BasicTable from '../../components/SubadminTable/Table'

export const SubAdmin = () => {
  return (
    <div>
      {/* <div className="admin-dashbord-heading">
        <h1>Courses</h1>
        <p>Lorem ipsum dolor sit amet, consectetur.</p>
      </div> */}
      <BasicTable/>
      {/* <MyCourses /> */}
    </div>
  )
}
