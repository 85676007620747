import * as React from "react";
import "./Table.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useEffect } from "react";
import { useUserAuth } from "../../Context/UseAuthContext";
import { Link } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import logo from "../../imgs/profile.png";


 


const makeStyle = (status) => {
  if (status) {
    return {
      background: "rgb(145 254 159 / 47%)",
      color: "green",
    };
  } else {
    return {
      background: "#ffadad8f",
      color: "red",
    };
  }
};

export default function BasicTable() {
  const {
    token,
    getSubadminsData,
    subadmins,
    verifiedSubadmin,
    showVerifaction,
    setShowVerificationstatus,
    singleSubadmin,
    handleSubadminVerifaction,

    FilterAuth,
    searchQuary,
    orderFilter,
    handleFilterAuthClick,
    handleSearchVendorNameChange,
    handleOrderFilter,
    handleSelectFilterChange,
  } = useUserAuth();

  useEffect(() => {
    if (token) {
      getSubadminsData();
    }
  }, [token, FilterAuth, searchQuary, orderFilter]);

  return (
    <div className="mycourses">
      <div className="mycourse-content">
        <h1>Courses</h1>
        <p>Best Courses Uploaded By Vendor.</p>
      </div>
      <div className="mycourse-data">
        <div className="mycourses-button">
          <button
            style={{ backgroundColor: FilterAuth === "" ? "#140342" : "" }}
            onClick={() => handleFilterAuthClick("")}
          >
            All Vendor
          </button>
          <button
            style={{ backgroundColor: FilterAuth ? "#140342" : "" }}
            onClick={() => handleFilterAuthClick(true)}
          >
            Verified
          </button>
          <button
            style={{ backgroundColor: FilterAuth === false ? "#140342" : "" }}
            onClick={() => handleFilterAuthClick(false)}
          >
            Not Verified
          </button>
        </div>
        <div className="dropdown-containers">
          <div className="search-container">
            <input
              type="text"
              placeholder="Search Vendor..."
              // value={searchQuery}
              onChange={handleSearchVendorNameChange}
            />
            <FiSearch className="mycourse-searchicon" />
          </div>
          <div className="category">
            <select value={-1} onChange={handleOrderFilter}>
              <option value={-1}>Sort By Recent</option>
              <option value={1}>Sort By Previous</option>
            </select>
          </div>
          <div className="review">
            <select value={true} onChange={handleSelectFilterChange}>
              <option value="">All Vendor</option>
              <option value={true}>Verified</option>
              <option value={false}>Not Verified</option>
            </select>
          </div>
        </div>
        <div className="mycourses-section1">
          {subadmins &&
            subadmins.map((course) => (
              <div key={course._id} className="mycourse-section-cards">
                {course.profileUrl ? (
                  <img src={course.profileUrl} alt={course.name} />
                ) : (
                  <img src={logo} alt={course.name} />
                )}
                <h4>{course.name}</h4>
                <Link
                  to={`/courses/${course._id}`}
                  className="mycourse-section-cards-a"
                >
                  All Course...
                </Link>

                <button
                  style={makeStyle(course.isVerified)}
                  className="mycourses-btn"
                  onClick={() => verifiedSubadmin(course)}
                >
                  {/* <img src="https://cdn-icons-png.freepik.com/512/6928/6928921.png" width='2vw' height='10px' />   */}
                  {course.isVerified ? "verified" : "Not Verified"}
                </button>
              </div>
            ))}
        </div>
        {/* <CourseList courses={filteredCourses} /> */}
      </div>
      <Dialog
        open={showVerifaction}
        onClose={() => setShowVerificationstatus(false)}
      >
        <DialogTitle>
          Are You sure you want to give{" "}
          {singleSubadmin.isVerified ? "Not Verified" : "Verified"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowVerificationstatus(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubadminVerifaction}>
            {singleSubadmin.isVerified ? "Not Verified" : "Verified"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
