import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUserAuth } from "../../../Context/UseAuthContext";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";

import { WithContext as ReactTags } from "react-tag-input";
import { ColorRing } from "react-loader-spinner";
import toast, { Toaster } from "react-hot-toast";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const Projects = () => {
  const { userID } = useParams();
  const {
    allMainCategory,

    allSubCategory,

    allCourseCategory,

    darkThemesetter,
  } = useUserAuth();
  const [sort, setSort] = useState(""); //mmk
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const [limit] = useState(6);
  const [projects, setProjects] = useState(null); //mmk

  const [loading, setLoading] = useState(false);
  const [OutputScreens, setOutputScreens] = useState(null);
  const [UploadCodeFile, setUploadCodeFile] = useState(null);
  const [UploadDocumentFile, setUploadDocumentFile] = useState(null);
  const [singleproject, setsingleProject] = useState("");
  const [editProjectTitle, setEditProjectTitle] = useState(""); // State to store edited name
  const [editProjectDescription, setEditDes] = useState("");
  const [editAbstract200Words, setEditAbstract200Words] = useState("");
  const [editPrice, setEditPrice] = useState(0);
  const [editTechnologiesUsed, setEditTechnologiesUsed] = useState(null);

  const [editMainCategory, setEditMainCategory] = useState(null);
  const [editSubCategory, setEditSubCategory] = useState(null);
  const [editCourseCategory, setEditCourseCategory] = useState(null);

  const [editUploadCodeFile, setEditUploadCodeFileUrl] = useState(null);
  const [editUploadDocumentUrl, setEditUploadDocumentFileUrl] = useState(null);
  const [editOutputScreens, setEditOutputScreensUrl] = useState(null);
  const [showform, setShowForm] = useState(false);

  // Percentage
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [showPercentageForm, setShowPercentageForm] = useState(false);
  const [editVendorPercentage, setEditVendorPercentage] = useState(null);
  const [editEnduserPercentage, setEditEnduserPercentage] = useState(null);
  const [editApprove, setEditApprove] = useState(null);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [projectIDToDelete, setprojectIDToDelete] = useState(null);

  const [filterProject, setFilterProject] = useState(null);
  const [expandedProjects, setExpandedProjects] = useState({});
  // ProgressBar
  const [imageUploadPogress, setImageUploadPogress] = useState(null);
  const [zipUploadPogress, setZipUploadPogress] = useState(null);
  const [pdfUploadPogress, setPdfPogress] = useState(null);
  const [uploadPogress, setUploadPogress] = useState(0);
  const [zipxhr, setzipxhr] = useState(null);
  const [imagexhr, setimagexhr] = useState(null);
  const [pdfxhr, setpdfxhr] = useState(null);

  //approval
  const [approvedPost, setApprovalPost] = useState(null);

  const postsTheme = darkThemesetter ? "dark-theme" : "light-theme";
  const postsText = darkThemesetter ? "dark-text" : "light-text";

  const inputColor = darkThemesetter ? "#f7f8fb" : "black";
  const labelColor = darkThemesetter ? "#f7f8fb" : "black";
  const borderColor = darkThemesetter ? "#f7f8fb" : "black";

  const adminBookmarksContainerDarktheme = darkThemesetter
    ? "admin-bookmarks-container-dark-theme"
    : "admin-bookmarks-container-light-theme";
  const adminBookmarksCoursesDarktheme = darkThemesetter
    ? "admin-bookmarks-courses-dark-theme"
    : "admin-bookmarks-courses-light-theme";

  const dialogContentContainer = darkThemesetter
    ? "dialog-content-contianer-dark-theme"
    : "light-theme";

  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleFilterChange = (e) => setFilter(e.target.value);
  const handleSortChange = (e) => setSort(e.target.value);
  const handlePageChange = (newPage) => setPage(newPage);

  const toggleExpand = (projectId) => {
    setExpandedProjects((prev) => ({
      ...prev,
      [projectId]: !prev[projectId],
    }));
  };

  const getProjects = () => {
    const url = userID
      ? `https://smartuter.com/api/adminproject/getproject/${userID}?sort=${sort}&search=${search}&filter=${filter}`
      : `https://smartuter.com/api/admin/getallsourseprojects?sort=${sort}&search=${search}&filter=${filter}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setProjects(res);
      })
      .catch((err) => console.log(err));
  };

  const showProjectForm = (e) => {
    setsingleProject(e);
    setEditProjectTitle(e.ProjectTitle); // Populate the name field with the selected e's name
    setEditDes(e.ProjectDescription);
    setEditAbstract200Words(e.Abstract200Words);
    setEditTechnologiesUsed(e.TechnologiesUsed);
    setEditUploadCodeFileUrl(e.UploadCodeFile);
    setEditUploadDocumentFileUrl(e.UploadDocumentFile);
    setEditOutputScreensUrl(e.OutputScreens);
    // Populate the tags field with the selected
    setEditCourseCategory(e.courseCategory);
    setEditSubCategory(e.subCategory);
    setEditMainCategory(e.mainCategory);
    setEditPrice(e.Price);
    setShowForm(true);
  };
  const handleFileChange = async (event) => {
    setLoading(true);
    const selectedFile = event.target.files[0];

    try {
      if (selectedFile.type.startsWith("image")) {
        // if (selectedFile.size < 1 * 1024 * 1024) {
        await handleUpload(selectedFile, "image");
        // } else {
        //   toast.error("Upload File Less Than 1 MB", {
        //     duration: 6000,
        //   });
        //   setLoading(false);
        // }
      } else if (
        selectedFile.type.startsWith("application/pdf") ||
        selectedFile.type === "application/msword" ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        selectedFile.type === "application/vnd.ms-powerpoint" ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        // if (selectedFile.size < 3 * 1024 * 1024) {
        await handleUpload(selectedFile, "pdf");
        // } else {
        //   toast.error("Upload File Less Than 3 MB", {
        //     duration: 6000,
        //   });
        //   setLoading(false);
        // }
      } else if (
        selectedFile.type === "application/zip" ||
        selectedFile.type === "application/x-zip-compressed"
      ) {
        // if (selectedFile.size < 5*1024 * 1024) {
        await handleUpload(selectedFile, "zip");
        // } else {
        //   toast.error("Upload File Less Than 5 MB", {
        //     duration: 6000,
        //   });
        //   setLoading(false);
        // }
      } else {
        setLoading(false);

        console.error("Unsupported file type");
        toast.error("Unsupported file type");
      }
    } catch (error) {
      setLoading(false);

      console.error("Error uploading file:", error.message);
    }
  };

  const handleUpload = async (file, fileType) => {
    // const formData = new FormData();
    // formData.append("file", file);

    // const response = await fetch(
    //   "https://smartuter.com/api/all-sub-admins/upload",
    //   {
    //     method: "POST",

    //     body: formData,
    //   }
    // );

    // if (response.ok) {
    //   const data = await response.json();
    //   handleUploadSuccess(data.fileUrl, fileType);
    // } else {
    //   console.error("Error uploading file:", response.statusText);
    //   throw new Error("Upload failed");
    // }

    try {
      const formData = new FormData();
      formData.append("file", file);
      const xhr = new XMLHttpRequest();

      xhr.open("POST", "https://smartuter.com/api/all-sub-admins/upload");

      // xhr.setRequestHeader(
      //   "Authorization",
      //   `Bearer ${localStorage.getItem("token")}`
      // );

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          // console.log(`Upload Progress: ${percentComplete.toFixed(2)}%`);
          setUploadPogress(percentComplete.toFixed(2));
          if (fileType === "image") {
            setImageUploadPogress(`${percentComplete.toFixed(2)}`);
            setimagexhr(xhr);
            return;
          }
          if (fileType === "zip") {
            setZipUploadPogress(`${percentComplete.toFixed(2)}`);
            setzipxhr(xhr);
            return;
          }

          if (
            fileType === "pdf" ||
            fileType === "ppt" ||
            fileType === "pptx" ||
            fileType === "doc" ||
            fileType === "docx"
          ) {
            setPdfPogress(`${percentComplete.toFixed(2)}`);
            setpdfxhr(xhr);
            return;
          }
        }
      });

      xhr.onload = function () {
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText);
          handleUploadSuccess(data.fileUrl, fileType);
        } else {
          console.error("Error uploading file:", xhr.statusText);
          toast.error("Upload failed");
        }
      };

      xhr.onerror = function () {
        console.error("Network error during upload");
        toast.error("Upload failed");
      };

      xhr.send(formData);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleUploadSuccess = (fileUrl, fileType) => {
    if (fileType === "image") {
      setOutputScreens(fileUrl);
      setLoading(false);
    } else if (fileType === "zip") {
      setUploadCodeFile(fileUrl);
      setLoading(false);
    } else if (fileType === "pdf") {
      setUploadDocumentFile(fileUrl);
      setLoading(false);
    } else {
      console.error("Unsupported file type");
      setLoading(false);
    }
  };
  const handleEditformSubmit = () => {
    if (
      !editProjectDescription ||
      !editProjectTitle ||
      !editPrice ||
      !editAbstract200Words ||
      !editTechnologiesUsed
    ) {
      alert("Please fill out all required fields.");
      return;
    }
    setShowForm(false);
    const payload = {
      ProjectDescription: editProjectDescription,
      ProjectTitle: editProjectTitle,
      Abstract200Words: editAbstract200Words,
      OutputScreens: OutputScreens ? OutputScreens : editOutputScreens,

      UploadCodeFile: UploadCodeFile ? UploadCodeFile : editUploadCodeFile,

      UploadDocumentFile: UploadDocumentFile
        ? UploadDocumentFile
        : editUploadDocumentUrl,
      Price: editPrice,
      mainCategory: editMainCategory,
      subCategory: editSubCategory,
      // courseCategory: editCourseCategory,
      TechnologiesUsed: editTechnologiesUsed,
    };
    const projectID = singleproject._id;
    fetch(`https://smartuter.com/api/adminproject/updateproject/${projectID}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        getProjects();
      })
      .catch((err) => console.log(err));
  };
  const handleDeleteproject = (e) => {
    const projectID = e._id;
    setprojectIDToDelete(projectID);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = () => {
    if (projectIDToDelete) {
      fetch(
        `https://smartuter.com/api/adminproject/deleteproject/${projectIDToDelete}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          getProjects();
        })
        .catch((err) => console.log(err));
    }
    // Close the confirmation popup
    setShowConfirmation(false);
  };
  const handleApprove = (e) => {
    setsingleProject(e);
    setShowPercentageForm(true);
    setEditEnduserPercentage(e.referralPercentage);
    setEditVendorPercentage(e.vendorPercentage);
    setEditApprove(e.approve);
  };
  const handleEditPercentageformSubmit = () => {
    const projectID = singleproject._id;

    const payload = {
      approve: editApprove,
      referralPercentage: editEnduserPercentage,
      vendorPercentage: editVendorPercentage,
    };

    if (!editVendorPercentage || !editEnduserPercentage) {
      alert("Please fill out all required fields.");
      return;
    }
    fetch(`https://smartuter.com/api/adminproject/approve/${projectID}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        getProjects();
        setShowPercentageForm(false);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getProjects();
  }, [search, filter, sort]);

  const handleFilter = () => {
    const filterProjects = projects.filter(
      (project) => project.courseCategory === "App"
    );
    setFilterProject(filterProjects);
  };

  const handleEditDelete = (i) => {
    setEditTechnologiesUsed(
      editTechnologiesUsed.filter((tag, index) => index !== i)
    );
  };

  const handleEditAddition = (tag) => {
    setEditTechnologiesUsed([...editTechnologiesUsed, tag]);
  };

  const handleEditDrag = (tag, currPos, newPos) => {
    const newTags = editTechnologiesUsed.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setEditTechnologiesUsed(newTags);
  };

  const handleTagEditClick = (index) => {};
  const handleChangeApprove = (e) => {
    setApprovalPost(e.target.value);
  };
  console.log(projects);
  return (
    <div>
      <div className={`adminBookmarksSection ${postsTheme}`}>
        <div className="adminBookmarksSectionHeaderText">
          <h1 className={postsText}>Projects</h1>
          <p>Let's have a look on Uploaded Projects.</p>
        </div>

        <div
          className={`adminBookmarksContainer ${adminBookmarksContainerDarktheme}`}
        >
          <div className="adminBookmarksContainerTitle">
            <h3>Projects</h3>
            {/* <select
                className="adminBookmarksTypeSelection"
                id="approvalStatus"
                onChange={handleChangeApprove}
                value={approvedPost}
              >
                <option value="">All</option>
                <option value="not_verified">Not Approved</option>
                <option value="verified">Approve</option>
              </select> */}
            <input
              type="text"
              placeholder="Search projects..."
              value={search}
              onChange={handleSearchChange}
              className="search-input"
            />
            <select
              value={filter}
              onChange={handleFilterChange}
              className="filter-select"
            >
              <option value="">All</option>
              <option value="verified">Verified</option>
              <option value="not_verified">Not Verified</option>
            </select>
            <select
              value={sort}
              onChange={handleSortChange}
              className="sort-select"
            >
              <option value="">Sort by</option>
              <option value="asc">Newest</option>
              <option value="desc">Oldest</option>
            </select>
          </div>

          <div className="adminBookmarksCoursesContainer">
            {projects &&
              projects.map((item) => (
                <div
                  className={`adminBookmarksCourseCard ${adminBookmarksCoursesDarktheme}`}
                  key={item._id}
                >
                  <img
                    className="adminBookmarkImage"
                    src={item.OutputScreens}
                    alt={item.ProjectTitle}
                  />
                  <div className="adminBookmarkActions">
                    <button
                      className="adminBookmarkActionEdit"
                      onClick={() => showProjectForm(item)}
                    >
                      Edit
                    </button>
                    <button
                      className="adminBookmarkActionDelete"
                      onClick={() => handleDeleteproject(item)}
                    >
                      Delete
                    </button>
                    <button
                      // variant="contained"
                      color={item.approve ? "success" : "success"}
                      style={{
                        backgroundColor: item.approve ? "red" : "green",
                      }}
                      onClick={() => handleApprove(item)}
                    >
                      {item.approve ? "DisApprove" : "Approve"}
                    </button>
                  </div>
                  <p
                    className={`adminBookmarkTitle ${postsText}`}
                    style={{ backgroundColor: "aliceblue" }}
                  >
                    {item.ProjectTitle}
                  </p>
                  <div
                    className={`adminBookmarksCourseCategories ${postsText}`}
                  >
                    <span
                      style={{
                        backgroundColor: "",
                        borderBottom: "1px solid orange",
                        margin: "2%",
                      }}
                    >
                      {item.mainCategory}
                    </span>
                    <span
                      style={{
                        backgroundColor: "",
                        borderBottom: "1px solid orange",
                        margin: "2%",
                      }}
                    >
                      {item.subCategory}
                    </span>
                    {/* <span
                        style={{
                          backgroundColor: "",
                          borderBottom: "1px solid orange",
                          margin: "2%",
                        }}
                      >
                        {item.courseCategory}
                      </span> */}
                  </div>
                  <div className="adminBookmarksCourseTechnologiesUsed">
                    {item.TechnologiesUsed.map((tech, idx) => (
                      <span key={idx}>#{tech.text}</span>
                    ))}
                  </div>
                  <div
                    className={`adminBookmarkDescription ${postsText} ${
                      expandedProjects[item._id] ? "expanded" : "collapsed"
                    }`}
                  >
                    <b>Project Description: </b>
                    {expandedProjects[item._id]
                      ? item.ProjectDescription
                      : item.ProjectDescription.substring(0, 170) + "..."}
                  </div>
                  <button
                    className="readMoreButton"
                    onClick={() => toggleExpand(item._id)}
                  >
                    {expandedProjects[item._id] ? "Read less" : "Read more"}
                  </button>
                </div>
              ))}
          </div>
        </div>

        {projects && projects.length <= 0 && (
          <div className="no-course-found-container">
            <img
              src="https://res.cloudinary.com/dyqpzx80s/image/upload/v1713333642/no-course-found_ifervi.jpg"
              className="no-course-found-img"
              alt="no-course"
            />
            <p className="no-course-found-text">No projects found.</p>
          </div>
        )}
      </div>
      <Dialog open={showform} onClose={() => setShowForm(false)}>
        <Toaster />
        <div className={dialogContentContainer}>
          <DialogTitle>Edit Project</DialogTitle>
          <DialogContent className={postsTheme}>
            <DialogContentText className={postsText}>
              Edit the selected Project:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="editProjectTitle"
              label="Title"
              type="text"
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: labelColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                },
                marginBottom: "20px",
              }}
              fullWidth
              value={editProjectTitle}
              onChange={(e) => setEditProjectTitle(e.target.value)} // Update editProjectTitle state
            />
            <TextField
              margin="dense"
              id="editProjectDescription"
              label="Project area"
              type="text"
              fullWidth
              multiline
              rows={4}
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: labelColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                },
                marginBottom: "20px",
              }}
              value={editProjectDescription}
              onChange={(e) => setEditDes(e.target.value)} // Update editProjectDescription state
            />
            <TextField
              margin="dense"
              id="editAbstract200Words"
              label="Abstract"
              type="text"
              fullWidth
              multiline
              projects={4}
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: labelColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                },
                marginBottom: "20px",
              }}
              value={editAbstract200Words}
              onChange={(e) => setEditAbstract200Words(e.target.value)} // Update editMessage state
            />
            Keywords/Tags
            <ReactTags
              tags={editTechnologiesUsed}
              // suggestions={suggestions}
              delimiters={delimiters}
              handleDelete={handleEditDelete}
              handleAddition={handleEditAddition}
              handleDrag={handleEditDrag}
              handleTagClick={handleTagEditClick}
              inputFieldPosition="bottom"
              autocomplete
            />
            Project output image
            {imageUploadPogress && loading && (
              <>
                <progress value={imageUploadPogress} max="100" />{" "}
                <button
                  onClick={() => (
                    imagexhr.abort(),
                    setImageUploadPogress(null),
                    setLoading(false),
                    setOutputScreens(null)
                  )}
                >
                  Cancel
                </button>
              </>
            )}
            <TextField
              margin="dense"
              id="image"
              // label="Upload Image..."
              type="file"
              fullWidth
              inputProps={{
                accept: "image/*",
              }}
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: labelColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                },
                marginBottom: "20px",
              }}
              onChange={handleFileChange}
              disabled={loading}
            />
            Upload project code zip
            {zipUploadPogress && loading && (
              <>
                <progress value={zipUploadPogress} max="100" />

                <button
                  onClick={() => (
                    zipxhr.abort(),
                    setZipUploadPogress(null),
                    setLoading(false),
                    setUploadCodeFile(null)
                  )}
                >
                  Cancel
                </button>
              </>
            )}
            <TextField
              margin="dense"
              id="zip"
              // label="Upload Zip..."
              type="file"
              fullWidth
              inputProps={{
                accept: ".zip",
              }}
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: labelColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                },
                marginBottom: "20px",
              }}
              onChange={handleFileChange}
              disabled={loading}
            />
            Upload project code document/pdf
            {pdfUploadPogress && loading && (
              <>
                <progress value={pdfUploadPogress} max="100" />

                <button
                  onClick={() => (
                    pdfxhr.abort(),
                    setPdfPogress(null),
                    setLoading(false),
                    setUploadDocumentFile(null)
                  )}
                >
                  Cancel
                </button>
              </>
            )}
            <TextField
              margin="dense"
              id="file"
              // label="Upload Pdf..."
              type="file"
              fullWidth
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: labelColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                },
                marginBottom: "20px",
              }}
              inputProps={{
                accept: ".pdf, .ppt, .pptx, .doc, .docx",
              }}
              onChange={handleFileChange}
              disabled={loading}
            />
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={editMainCategory}
              label="Main Category"
              sx={{
                color: inputColor,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                  "&:hover fieldset": {
                    borderColor: borderColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: borderColor,
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: inputColor,
                },
                marginBottom: "20px",
              }}
              style={{ width: "100%" }}
              onChange={(e) => setEditMainCategory(e.target.value)}
            >
              {allMainCategory &&
                allMainCategory.map((el, i) => (
                  <MenuItem
                    key={el._id}
                    value={el.category}
                    sx={{
                      color: darkThemesetter ? "black" : "black",
                      background: darkThemesetter
                        ? "rgb(88 85 85 / 87%)"
                        : "#f7f8fb",
                    }}
                  >
                    {el.category}
                  </MenuItem>
                ))}
            </Select>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={editSubCategory}
              label="Sub Category"
              sx={{
                color: inputColor,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                  "&:hover fieldset": {
                    borderColor: borderColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: borderColor,
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: inputColor,
                },
                marginBottom: "20px",
              }}
              style={{ width: "100%" }}
              onChange={(e) => setEditSubCategory(e.target.value)}
            >
              {allSubCategory &&
                allSubCategory.map((el, i) => (
                  <MenuItem
                    key={el._id}
                    value={el.category}
                    sx={{
                      color: darkThemesetter ? "black" : "black",
                      background: darkThemesetter
                        ? "rgb(88 85 85 / 87%)"
                        : "#f7f8fb",
                    }}
                  >
                    {el.category}
                  </MenuItem>
                ))}
            </Select>
            {/* ChildCategory
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={editCourseCategory}
              label="Course Category"
              sx={{
                color: inputColor,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                  "&:hover fieldset": {
                    borderColor: borderColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: borderColor,
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: inputColor,
                },
              }}
              style={{ width: "100%" }}
              onChange={(e) => setEditCourseCategory(e.target.value)}
            >
              {allCourseCategory &&
                allCourseCategory.map((el, i) => (
                  <MenuItem
                    key={el._id}
                    value={el.category}
                    sx={{
                      color: darkThemesetter ? "black" : "black",
                      background: darkThemesetter
                        ? "rgb(88 85 85 / 87%)"
                        : "#f7f8fb",
                    }}
                  >
                    {el.category}
                  </MenuItem>
                ))}
            </Select> */}
            <TextField
              autoFocus
              margin="dense"
              id="editProjectTitle"
              label="Cost"
              type="number"
              fullWidth
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: labelColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                },
                marginBottom: "20px",
              }}
              value={editPrice}
              onChange={(e) => setEditPrice(e.target.value)} // Update editProjectTitle state
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => (setShowForm(false), setLoading(false))}>
              Cancel
            </Button>
            {loading ? (
              <Button>in Progress {uploadPogress}%</Button>
            ) : (
              // <ColorRing
              //   visible={true}
              //   height="80"
              //   width="80"
              //   ariaLabel="color-ring-loading"
              //   wrapperStyle={{}}
              //   wrapperClass="color-ring-wrapper"
              //   colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              // />
              <Button onClick={handleEditformSubmit}>Submit</Button>
            )}
          </DialogActions>
        </div>
      </Dialog>

      {/* upade percentage */}
      <Dialog
        open={showPercentageForm}
        onClose={() => setShowPercentageForm(false)}
      >
        <div className={postsTheme}>
          <DialogTitle>ADD PERCENTAGE</DialogTitle>
          <DialogContent>
            <DialogContentText className={postsText}>
              Edit the selected Percentage:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="editProjectTitle"
              label="EditVendorPercentage"
              type="text"
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: labelColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                },
                marginBottom: "20px",
              }}
              fullWidth
              value={editVendorPercentage}
              onChange={(e) => setEditVendorPercentage(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="editProjectTitle"
              label="EditEnduserPercentage"
              type="text"
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: labelColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                },
                marginBottom: "20px",
              }}
              fullWidth
              value={editEnduserPercentage}
              onChange={(e) => setEditEnduserPercentage(e.target.value)}
            />
            <h4>Approve/DisApprove</h4>
            <Switch
              {...label}
              checked={editApprove}
              onChange={(e) => setEditApprove(e.target.checked)}
              name="switch"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowPercentageForm(false)}>Cancel</Button>
            {loading ? (
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              />
            ) : (
              <Button onClick={handleEditPercentageformSubmit}>Submit</Button>
            )}
          </DialogActions>
        </div>
      </Dialog>
      {/* deleteprojectmodal */}
      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
      >
        <div className={postsTheme}>
          <DialogTitle>
            Are you sure you want to delete this project ?
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => setShowConfirmation(false)}>Cancel</Button>
            <Button onClick={handleConfirmDelete}>Delete</Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default Projects;
