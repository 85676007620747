import React from 'react' 
import Analytics from '../components/analytics/analytics'

export const Home = () => {
  return (
    <div style={{border:"0px ",maxHeight:"80vh",overflowY:"auto"}}>
     
      <Analytics />
    </div>
  )
}
