import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Tree from "react-d3-tree";
import "./SingleEndUserTree.css";
import { useUserAuth } from "../../Context/UseAuthContext";
import { useNavigate } from "react-router-dom";

const SingleEndUserTree = ({referralCount}) => {
  const { referralNotification, getNotification } = useUserAuth();
  const { id, name } = useParams();
  const [treeData, setTreeData] = useState({ name: name, children: [] });
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  const [highlightedNodes, setHighlightedNodes] = useState([]);

  const fetchTreeData = async (id, name) => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://smartuter.com/api/enduser/getTree",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: id }),
        }
      );

      if (response.ok) {
        const result = await response.json();

      console.log(result,"36 single end user tree")
        const data = result.userTree;
        const formattedData = buildTree(data);
        setTreeData({
          name: name,
          children: formattedData,
          referralCount
        });
      } else {
        console.error("Failed to fetch tree data");
      }
    } catch (error) {
      console.error("Error fetching tree data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id && name) {
      fetchTreeData(id, name);
    }
  }, [id, name]);

  useEffect(() => {
    if (referralNotification && referralNotification.length > 0) {
      const newHighlightedNodes = new Set(highlightedNodes);

      referralNotification.forEach((referralId) => {
        newHighlightedNodes.add(referralId);
        setTimeout(() => {
          setHighlightedNodes((prev) => prev.filter((id) => id !== referralId));
          handleDeleteReferralIds([referralId]);
          getNotification();
        }, 1 * 60 * 1000); // 1 minute in milliseconds
      });

      setHighlightedNodes(Array.from(newHighlightedNodes));
    }
  }, [referralNotification]);

  const handleDeleteReferralIds = async (idsToDelete) => {
    try {
      const response = await fetch(
        "https://smartuter.com/api/notification/deletereferralIds",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ referralIds: idsToDelete }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete referral IDs");
      }

      const data = await response.json();
    } catch (error) {
      console.error("Error deleting referral IDs:", error.message);
    }
  };

  const buildTree = (users) => {
    let count1 = 0;

    const createLevelNodes = (nodes) => {
      const levelNodes = [];
      for (let i = 0; i < nodes.length; i += 2) {
        const children = nodes.slice(i, i + 2);
        levelNodes.push({
          name: `level${i / 2 + 1}`,
          children: children,
        });
      }
      return levelNodes;
    };
    return createLevelNodes(
      users.map(
        (user) => (
          (count1 += 1),
          setCount(count1),
          {
            name: (user.name.toLowerCase().substring(0,5)+"::"+(user.referralCount?user.referralCount:0)),
            attributes: {
              id: user._id,
              email: user.email,
            },
            children: user.children ? buildTree(user.children) : [], // Recursively build children
          }
        )
      )
    );
  };
  const handleNodeClick =  (nodeDatum) => {
    const userId = nodeDatum && nodeDatum.attributes && nodeDatum.attributes.id;
    const name = nodeDatum.name;
     fetchTreeData(userId,name);
     setCount(0);
    navigate(`/allendusers/${userId}/${name}`);
  };
  const customNode = ({ nodeDatum }) => {
    console.log(nodeDatum)
    const isHighlighted = highlightedNodes.includes(
      nodeDatum && nodeDatum.attributes && nodeDatum.attributes.id
    );

    const getColor = (node) =>
      isHighlighted 
        ? "green"
        : node.attributes && node.attributes.id
        ? "darkblue"
        : "gray";

    const nodeColor = getColor(nodeDatum);
    const dummyImageURL =
      "https://cdn.pixabay.com/photo/2015/03/04/22/35/head-659651_960_720.png";

    const isTopNode = nodeDatum.name === treeData.name;

    return (
      <g onClick={() => handleNodeClick(nodeDatum)}>
        <circle r="15" fill={nodeColor} />
        <image href={dummyImageURL} x="-15" y="-15" width="30" height="30" />
        <text fill="black" fontWeight="normal" strokeWidth="1" x="20" y="5">
          {nodeDatum.name}
        </text>
        {isTopNode && (
          <text fill="black" fontWeight="normal" x="20" y="25">
            Referrals: {referralCount}
          </text>
        )}
      </g>
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div id="treeWrapper" style={{ width: "100%", height: "100vh" }}>
      <button className="refer-button">No of Referrals:{count}</button>
      <Tree
        svgClassName="link__path"
        data={treeData}
        renderCustomNodeElement={customNode}
        orientation="vertical"
        pathFunc="elbow"
        separation={{ siblings: 1, nonSiblings: 1 }}
        translate={{ x: 100, y: 50 }}
        zoomable={true}
        collapsible={true}
      />
    </div>
  );
};

export default SingleEndUserTree;
