import RightSide from "../RigtSide/RightSide";
import Logo from "../../imgs/logo.png";
import { LuSunMoon } from "react-icons/lu";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoMoonOutline } from "react-icons/io5";
import { useUserAuth } from "../../Context/UseAuthContext";
import "./navbarDashbordAdmin.css";
import { useState } from "react";
import Notification from "../Notification/Notification";

const NavbarDashboardAdmin = () => {
  const { darkTheme, darkThemesetter, notification } = useUserAuth();
  const [isNotificationPageVisible, setNotificationPageVisible] =
    useState(false);

  const toggleNotificationPage = () => {
    setNotificationPageVisible(!isNotificationPageVisible);
  };

  const darkClassName = darkThemesetter ? "nav-dark" : "nav-light";
  return (
    <nav className={`navbar-dashboard ${darkClassName}`}>
      {/* <h1 className="navbar-dashboard-head">Smarttuter</h1> */}
      <div className="admitdashbord-logo">
        <img src={Logo} alt="logo" />
        <span>{/* Sh<span>o</span>ps */}</span>
      </div>
      <div className="right-side-bar">
        <div className="navbar-noti-icons">
          {darkThemesetter ? (
            <LuSunMoon
              className="moon-dark-theme"
              size="30"
              onClick={darkTheme}
            />
          ) : (
            <IoMoonOutline
              className="moon-dark-theme"
              size="30"
              onClick={darkTheme}
            />
          )}
          <div className="navbar_notification">
            <IoIosNotificationsOutline
              className={
                isNotificationPageVisible
                  ? `notification-bell${"-active"}`
                  : "notification-bell"
              }
              size="30"
              onClick={toggleNotificationPage}
            />

            <p>
              {notification &&
                notification.filter((el) => el.AdminOpen == false).length}
            </p>
          </div>
          {isNotificationPageVisible && (
            <Notification
              setNotificationPageVisible={setNotificationPageVisible}
              isNotificationPageVisible={isNotificationPageVisible}
            />
          )}
        </div>
        <div>
          {/* <RightSide/> */}
          <RightSide />
        </div>
      </div>
    </nav>
  );
};
export default NavbarDashboardAdmin;
