import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUserAuth } from "../../../Context/UseAuthContext";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Switch,
} from "@mui/material";
import "./post.css";

import { WithContext as ReactTags } from "react-tag-input";
import { ColorRing } from "react-loader-spinner";
import { ImCross } from "react-icons/im";

import { SiVerizon } from "react-icons/si";

import toast, { Toaster } from "react-hot-toast";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const Posts = () => {
  const { userID } = useParams();
  const {
    allMainCategory,
    allSubCategory,
    allCourseCategory,
    darkThemesetter,
  } = useUserAuth();

  const [sort, setSort] = useState(""); //mmk
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const [limit] = useState(6);
  const [posts, setPosts] = useState(null); //mmk

  const [loading, setLoading] = useState(false);
  const [imageURL, setImage] = useState(null);
  const [videoURL, setVideoUrl] = useState(null);
  const [zipURL, setZipUrl] = useState(null);
  const [singlepost, setsingelPost] = useState("");
  const [editName, setEditName] = useState(""); // State to store edited name
  const [editMessage, setEditMessage] = useState("");
  const [editPrice, setEditPrice] = useState(0);
  const [editTags, setEditTags] = useState(null);
  const [editMainCategory, setEditMainCategory] = useState(null);
  const [editSubCategory, setEditSubCategory] = useState(null);
  const [editCourseCategory, setEditCourseCategory] = useState(null);
  const [editVideoUrl, setEditVideoUrl] = useState(null);
  const [editZipUrl, setEditZipUrl] = useState(null);
  const [editImageUrl, setEditImageUrl] = useState(null);
  const [showform, setShowForm] = useState(false);

  // Percentage
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [showPercentageForm, setShowPercentageForm] = useState(false);
  const [editVendorPercentage, setEditVendorPercentage] = useState(null);
  const [editEnduserPercentage, setEditEnduserPercentage] = useState(null);
  const [editApprove, setEditApprove] = useState(null);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [postIdToDelete, setPostIdToDelete] = useState(null);
  // ProgressBar
  const [imageUploadPogress, setImageUploadPogress] = useState(null);
  const [zipUploadPogress, setZipUploadPogress] = useState(null);
  const [pdfUploadPogress, setVideoPogress] = useState(null);
  const [uploadPogress, setUploadPogress] = useState(0);
  const [zipxhr, setzipxhr] = useState(null);
  const [imagexhr, setimagexhr] = useState(null);
  const [pdfxhr, setvideoxhr] = useState(null);
  //approve
  const [approvedPost, setApprovalPost] = useState(null);

  const postsTheme = darkThemesetter ? "dark-theme" : "light-theme";
  const postsText = darkThemesetter ? "dark-text" : "light-text";

  const inputColor = darkThemesetter ? "#f7f8fb" : "black";
  const labelColor = darkThemesetter ? "#f7f8fb" : "black";
  const borderColor = darkThemesetter ? "#f7f8fb" : "black";

  const adminBookmarksContainerDarktheme = darkThemesetter
    ? "admin-bookmarks-container-dark-theme"
    : "admin-bookmarks-container-light-theme";
  const adminBookmarksCoursesDarktheme = darkThemesetter
    ? "admin-bookmarks-courses-dark-theme"
    : "admin-bookmarks-courses-light-theme";

  const dialogContentContainer = darkThemesetter
    ? "dialog-content-contianer-dark-theme"
    : "light-theme";

  const handleSearchChange = (e) => setSearch(e.target.value); //mmk
  const handleFilterChange = (e) => setFilter(e.target.value);
  const handleSortChange = (e) => setSort(e.target.value);
  const handlePageChange = (newPage) => setPage(newPage); //mmk

  const getPosts = () => {
    const url = userID
      ? `https://smartuter.com/api/all-sub-admins/getpost/${userID}?sort=${sort}&search=${search}&filter=${filter}`
      : `https://smartuter.com/api/admin/getallsoursecourses?sort=${sort}&search=${search}&filter=${filter}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setPosts(res);
      })
      .catch((err) => console.log(err));
  };
  const showPostForm = (e) => {
    setsingelPost(e);
    setEditName(e.postName); // Populate the name field with the selected e's name
    setEditMessage(e.postMessage);
    setEditTags(e.tags);
    setEditVideoUrl(e.videoUrl);
    setEditZipUrl(e.zipUrl);
    setEditImageUrl(e.imageUrl);
    setEditMainCategory(e.mainCategory);
    setEditSubCategory(e.subCategory);
    setEditCourseCategory(e.courseCategory);
    // Populate the tags field with the selected
    setEditPrice(e.price);
    setShowForm(true);
  };
  const handleFileChange = async (event) => {
    setLoading(true);
    const selectedFile = event.target.files[0];

    try {
      if (selectedFile.type.startsWith("image")) {
        // if (selectedFile.size < 1 * 1024 * 1024) {
        await handleUpload(selectedFile, "image");
        // } else {
        //   toast.error("Upload File Less Than 1 MB", {
        //     duration: 6000,
        //   });
        //   setLoading(false);
        // }
      } else if (selectedFile.type.startsWith("video")) {
        // if (selectedFile.size < 5 * 1024 * 1024) {
        await handleUpload(selectedFile, "video");
        // } else {
        //   toast.error("Upload File Less Than 5 MB", {
        //     duration: 6000,
        //   });
        //   setLoading(false);
        // }
      } else if (
        selectedFile.type === "application/zip" ||
        selectedFile.type === "application/x-zip-compressed"
      ) {
        // if (selectedFile.size < 5*1024 * 1024) {
        await handleUpload(selectedFile, "zip");
        // } else {
        //   toast.error("Upload File Less Than 5 MB", {
        //     duration: 6000,
        //   });
        //   setLoading(false);
        // }
      } else {
        console.error("Unsupported file type", selectedFile);
        setLoading(false);

        toast.error("Unsupported file type");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Unsupported file type");
      console.error("Error uploading file:", error.message);
    }
  };

  const handleUpload = async (file, fileType) => {
    const formData = new FormData();
    formData.append("file", file);

    // const response = await fetch(
    //   "https://smartuter.com/api/all-sub-admins/upload",
    //   {
    //     method: "POST",

    //     body: formData,
    //   }
    // );

    // if (response.ok) {
    //   const data = await response.json();
    //   handleUploadSuccess(data.fileUrl, fileType);
    // } else {
    //   console.error("Error uploading file:", response.statusText);
    //   throw new Error("Upload failed");
    // }

    try {
      const formData = new FormData();
      formData.append("file", file);
      const xhr = new XMLHttpRequest();

      xhr.open("POST", "https://smartuter.com/api/all-sub-admins/upload");

      // xhr.setRequestHeader(
      //   "Authorization",
      //   `Bearer ${localStorage.getItem("token")}`
      // );

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          // console.log(`Upload Progress: ${percentComplete.toFixed(2)}%`);
          setUploadPogress(percentComplete.toFixed(2));
          if (fileType === "image") {
            setZipUploadPogress(null);
            setVideoPogress(null);
            setImageUploadPogress(`${percentComplete.toFixed(2)}`);

            setimagexhr(xhr);
            return;
          }
          if (fileType === "zip") {
            setVideoPogress(null);
            setImageUploadPogress(null);
            setZipUploadPogress(`${percentComplete.toFixed(2)}`);

            setzipxhr(xhr);
            return;
          }

          if (fileType === "video") {
            setImageUploadPogress(null);
            setZipUploadPogress(null);
            setVideoPogress(`${percentComplete.toFixed(2)}`);

            setvideoxhr(xhr);
            return;
          }
        }
      });

      xhr.onload = function () {
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText);
          handleUploadSuccess(data.fileUrl, fileType);
        } else {
          console.error("Error uploading file:", xhr.statusText);
          toast.error("Upload failed");
        }
      };

      xhr.onerror = function () {
        console.error("Network error during upload");
        toast.error("Upload failed");
      };

      xhr.send(formData);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleUploadSuccess = (fileUrl, fileType) => {
    if (fileType === "image") {
      setImage(fileUrl);

      setLoading(false);
    } else if (fileType === "video") {
      setVideoUrl(fileUrl);
      setLoading(false);
    } else if (fileType === "zip") {
      setZipUrl(fileUrl);
      setLoading(false);
    } else {
      console.error("Unsupported file type");
      setLoading(false);
    }
  };
  const handleEditformSubmit = () => {
    if (
      !editMessage ||
      !editName ||
      !editPrice ||
      !editMainCategory ||
      !editTags
    ) {
      alert("Please fill out all required fields.");
      return;
    }

    setShowForm(false);

    const payload = {
      postMessage: editMessage,
      postName: editName,
      imageUrl: imageURL ? imageURL : editImageUrl,
      videoUrl: videoURL ? videoURL : editVideoUrl,
      zipUrl: zipURL ? zipURL : editZipUrl,
      price: editPrice,
      mainCategory: editMainCategory,
      subCategory: editSubCategory,
      // courseCategory: editCourseCategory,
      tags: editTags,
    };

    const postID = singlepost._id;
    fetch(`https://smartuter.com/api/all-sub-admins/updatepost/${postID}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        getPosts();
      })
      .catch((err) => console.log(err));
  };
  const handleDeletepost = (e) => {
    const postID = e._id;
    setPostIdToDelete(postID);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = () => {
    if (postIdToDelete) {
      fetch(
        `https://smartuter.com/api/all-sub-admins/deletepost/${postIdToDelete}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          getPosts();
        })
        .catch((err) => console.log(err));
    }
    // Close the confirmation popup
    setShowConfirmation(false);
  };
  const handleApprove = (e) => {
    setsingelPost(e);

    setShowPercentageForm(true);
    setEditEnduserPercentage(e.referralPercentage);
    setEditVendorPercentage(e.vendorPercentage);
    setEditApprove(e.approve);
  };

  const handleEditPercentageformSubmit = () => {
    const payload = {
      approve: editApprove,
      referralPercentage: editEnduserPercentage,
      vendorPercentage: editVendorPercentage,
    };

    if (!editVendorPercentage || !editEnduserPercentage) {
      alert("Please fill out all required fields.");
      return;
    }
    const postID = singlepost._id;
    fetch(`https://smartuter.com/api/all-sub-admins/approve/${postID}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        getPosts();
        setShowPercentageForm(false);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getPosts();
  }, [search, filter, sort]);

  const handleEditDelete = (i) => {
    setEditTags(editTags.filter((tag, index) => index !== i));
  };

  const handleEditAddition = (tag) => {
    setEditTags([...editTags, tag]);
  };

  const handleEditDrag = (tag, currPos, newPos) => {
    const newTags = editTags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setEditTags(newTags);
  };

  const handleTagEditClick = (index) => {};

  const [expandedPosts, setExpandedPosts] = useState({});

  const toggleExpand = (postId) => {
    setExpandedPosts((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
  };

  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + "...";
    }
    return text;
  };

  const handleChangeApprove = (e) => {
    setApprovalPost(e.target.value);
  };
  return (
    <div>
      {loading && (
        <div className="loading-overlay">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={["#73a9c2", "#73a9c2", "#73a9c2", "#73a9c2", "#73a9c2"]}
            // colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </div>
      )}
      <div className={`adminBookmarksSection ${postsTheme}`}>
        <div className="adminBookmarksSectionHeaderText">
          <h1 className={postsText}>Courses</h1>
          <p>Let's have a look on Uploaded Courses.</p>
        </div>

        <div
          className={`adminBookmarksContainer ${adminBookmarksContainerDarktheme}`}
        >
          <div className="adminBookmarksContainerTitle">
            <h3>Courses</h3>
            {/* <select
                className="adminBookmarksTypeSelection"
                id="approvalStatus"
                onChange={handleChangeApprove}
                value={approvedPost}
              >
                <option value="">All</option>
                <option value="not_verified">Not Approved</option>
                <option value="verified">Approve</option>
              </select> */}
            <input
              type="text"
              placeholder="Search projects..."
              value={search}
              onChange={handleSearchChange}
              className="search-input"
            />
            <select
              value={filter}
              onChange={handleFilterChange}
              className="filter-select"
            >
              <option value="">All</option>
              <option value="verified">Verified</option>
              <option value="not_verified">Not Verified</option>
            </select>
            <select
              value={sort}
              onChange={handleSortChange}
              className="sort-select"
            >
              <option value="">Sort by</option>
              <option value="asc">Newest</option>
              <option value="desc">Older</option>
            </select>
          </div>

          <div className="adminBookmarksCoursesContainer">
            {posts &&
              posts.map((item) => (
                <div
                  className={`adminBookmarksCourseCard ${adminBookmarksCoursesDarktheme}`}
                  key={item._id}
                >
                  <img
                    className="adminBookmarkImage"
                    src={item.imageUrl}
                    alt="bookmark-course"
                  />
                  <div className="adminBookmarkActions">
                    <button
                      className="adminBookmarkActionEdit"
                      onClick={() => showPostForm(item)}
                    >
                      Edit
                    </button>
                    <button
                      className="adminBookmarkActionDelete"
                      onClick={() => handleDeletepost(item)}
                    >
                      Delete
                    </button>
                    <button
                      color={item.approve ? "success" : "success"}
                      style={{
                        backgroundColor: item.approve ? "red" : "green",
                      }}
                      onClick={() => handleApprove(item)}
                    >
                      {item.approve ? "DisApprove" : "Approve"}
                    </button>
                  </div>
                  <div
                    className={`adminBookmarksCourseCategories ${postsText}`}
                  >
                    <span
                      style={{
                        backgroundColor: "",
                        borderBottom: "1px solid orange",
                        margin: "2%",
                      }}
                    >
                      {item.mainCategory}
                    </span>
                    <span
                      style={{
                        backgroundColor: "",
                        borderBottom: "1px solid gray",
                        margin: "2%",
                      }}
                    >
                      {item.subCategory}
                    </span>
                    {/* <span
                        style={{
                          backgroundColor: "",
                          borderBottom: "1px solid gold",
                          margin: "2%",
                        }}
                      >
                        {item.courseCategory}
                      </span> */}
                  </div>
                  <p
                    className={`adminBookmarkTitle ${postsText}`}
                    style={{ backgroundColor: "aliceblue" }}
                  >
                    {item.postName}
                  </p>
                  <div className="adminBookmarksCourseTechnologiesUsed">
                    {item.tags.map((tech, idx) => (
                      <span key={idx}>#{tech.text}</span>
                    ))}
                  </div>
                  <p className={`adminBookmarkDescription ${postsText}`}>
                    <b>Course Desc: </b>
                    {expandedPosts[item._id]
                      ? item.postMessage
                      : truncateText(item.postMessage, 200)}
                    <button
                      className="readMoreButton"
                      onClick={() => toggleExpand(item._id)}
                    >
                      {expandedPosts[item._id] ? "Read less" : "Read more"}
                    </button>
                  </p>
                </div>
              ))}
          </div>
        </div>

        {posts && posts.length <= 0 && (
          <div className="no-course-found-container">
            <img
              src="https://res.cloudinary.com/dyqpzx80s/image/upload/v1713333642/no-course-found_ifervi.jpg"
              className="no-course-found-img"
              alt="no-course"
            />
            <p className="no-course-found-text">No courses found.</p>
          </div>
        )}
      </div>
      <Dialog open={showform} onClose={() => setShowForm(false)}>
        <Toaster />
        <div className={dialogContentContainer}>
          <DialogTitle>Edit Element</DialogTitle>
          <DialogContent className={postsTheme}>
            <DialogContentText className={postsText}>
              Edit the selected Course:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="editName"
              label="Title"
              type="text"
              fullWidth
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: labelColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                },
                marginBottom: "20px",
              }}
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
              required // Update editName state
            />
            <TextField
              margin="dense"
              id="editMessage"
              label="Course area"
              type="text"
              fullWidth
              multiline
              rows={4}
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: labelColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                },
                marginBottom: "20px",
              }}
              value={editMessage}
              onChange={(e) => setEditMessage(e.target.value)}
              required // Update editMessage state
            />
            Keywords/Tags
            <ReactTags
              tags={editTags}
              // suggestions={suggestions}
              delimiters={delimiters}
              handleDelete={handleEditDelete}
              handleAddition={handleEditAddition}
              handleDrag={handleEditDrag}
              handleTagClick={handleTagEditClick}
              inputFieldPosition="bottom"
              autocomplete
            />
            Course output image{" "}
            {imageUploadPogress && loading && (
              <>
                <progress value={imageUploadPogress} max="100" />{" "}
                <button
                  onClick={() => (
                    imagexhr.abort(),
                    setImageUploadPogress(null),
                    setLoading(false),
                    setImage(null)
                  )}
                >
                  Cancel
                </button>
              </>
            )}
            <TextField
              margin="dense"
              id="image"
              // label="Upload Image..."
              type="file"
              fullWidth
              inputProps={{
                accept: "image/*",
              }}
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: labelColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                },
                marginBottom: "20px",
              }}
              onChange={handleFileChange}
              disabled={loading}
            />
            upload video
            {pdfUploadPogress && loading && (
              <>
                <progress value={pdfUploadPogress} max="100" />

                <button
                  onClick={() => (
                    pdfxhr.abort(),
                    setVideoPogress(null),
                    setLoading(false),
                    setVideoUrl(null)
                  )}
                >
                  Cancel
                </button>
              </>
            )}
            <TextField
              margin="dense"
              id="video"
              // label="Upload Video..."
              type="file"
              fullWidth
              inputProps={{
                accept: "video/*",
              }}
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: labelColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                },
                marginBottom: "20px",
              }}
              onChange={handleFileChange}
              disabled={loading}
            />
            upload zip
            {zipUploadPogress && loading && (
              <>
                <progress value={zipUploadPogress} max="100" />

                <button
                  onClick={() => (
                    zipxhr.abort(),
                    setZipUploadPogress(null),
                    setLoading(false),
                    setZipUrl(null)
                  )}
                >
                  Cancel
                </button>
              </>
            )}
            <TextField
              margin="dense"
              id="file"
              // label="Upload Zip..."
              type="file"
              fullWidth
              inputProps={{
                accept: ".zip",
              }}
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: labelColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                },
                marginBottom: "20px",
              }}
              onChange={handleFileChange}
              disabled={loading}
            />
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={editMainCategory}
              label="Main Category"
              sx={{
                color: inputColor,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                  "&:hover fieldset": {
                    borderColor: borderColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: borderColor,
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: inputColor,
                },
                marginBottom: "20px",
              }}
              style={{ width: "100%" }}
              onChange={(e) => setEditMainCategory(e.target.value)}
            >
              {allMainCategory &&
                allMainCategory.map((el, i) => (
                  <MenuItem
                    key={el._id}
                    value={el.category}
                    sx={{
                      color: darkThemesetter ? "black" : "black",
                      background: darkThemesetter
                        ? "rgb(88 85 85 / 87%)"
                        : "#f7f8fb",
                    }}
                  >
                    {el.category}
                  </MenuItem>
                ))}
            </Select>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={editSubCategory}
              label="Sub Category"
              sx={{
                color: inputColor,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                  "&:hover fieldset": {
                    borderColor: borderColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: borderColor,
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: inputColor,
                },
                marginBottom: "20px",
              }}
              style={{ width: "100%" }}
              onChange={(e) => setEditSubCategory(e.target.value)}
            >
              {allSubCategory &&
                allSubCategory.map((el, i) => (
                  <MenuItem
                    key={el._id}
                    value={el.category}
                    sx={{
                      color: darkThemesetter ? "black" : "black",
                      background: darkThemesetter
                        ? "rgb(88 85 85 / 87%)"
                        : "#f7f8fb",
                    }}
                  >
                    {el.category}
                  </MenuItem>
                ))}
            </Select>
            {/* ChildCategory
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={editCourseCategory}
              label="Course Category"
              sx={{
                color: inputColor,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                  "&:hover fieldset": {
                    borderColor: borderColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: borderColor,
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: inputColor,
                },
              }}
              style={{ width: "100%" }}
              onChange={(e) => setEditCourseCategory(e.target.value)}
            >
              {allCourseCategory &&
                allCourseCategory.map((el, i) => (
                  <MenuItem
                    key={el._id}
                    value={el.category}
                    sx={{
                      color: darkThemesetter ? "black" : "black",
                      background: darkThemesetter
                        ? "rgb(88 85 85 / 87%)"
                        : "#f7f8fb",
                    }}
                  >
                    {el.category}
                  </MenuItem>
                ))}
            </Select> */}
            <TextField
              autoFocus
              margin="dense"
              id="editName"
              label="Cost"
              type="number"
              fullWidth
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: labelColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                },
                marginBottom: "20px",
              }}
              value={editPrice}
              onChange={(e) => setEditPrice(e.target.value)} // Update editName state
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => (setShowForm(false), setLoading(false))}>
              Cancel
            </Button>
            {loading ? (
              <Button>in Progress {uploadPogress}%</Button>
            ) : (
              // <ColorRing
              //   visible={true}
              //   height="80"
              //   width="80"
              //   ariaLabel="color-ring-loading"
              //   wrapperStyle={{}}
              //   wrapperClass="color-ring-wrapper"
              //   colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              // />
              <Button onClick={handleEditformSubmit}>Submit</Button>
            )}
          </DialogActions>
        </div>
      </Dialog>

      {/* upade percentage */}
      <Dialog
        open={showPercentageForm}
        onClose={() => setShowPercentageForm(false)}
      >
        <div className={postsTheme}>
          <DialogTitle>ADD PERCENTAGE</DialogTitle>
          <DialogContent>
            <DialogContentText className={postsText}>
              Edit the selected Percentage:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="editProjectTitle"
              label="EditVendorPercentage"
              type="text"
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: labelColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                },
                marginBottom: "20px",
              }}
              fullWidth
              value={editVendorPercentage}
              onChange={(e) => setEditVendorPercentage(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="editProjectTitle"
              label="EditEnduserPercentage"
              type="text"
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: labelColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor,
                  },
                },
              }}
              fullWidth
              value={editEnduserPercentage}
              onChange={(e) => setEditEnduserPercentage(e.target.value)}
            />
            <h4>Approve/DisApprove</h4>
            <Switch
              {...label}
              checked={editApprove}
              onChange={(e) => setEditApprove(e.target.checked)}
              name="switch"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowPercentageForm(false)}>Cancel</Button>
            {loading ? (
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              />
            ) : (
              <Button onClick={handleEditPercentageformSubmit}>Submit</Button>
            )}
          </DialogActions>
        </div>
      </Dialog>
      {/* deletepostmodal */}
      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
      >
        <div className={postsTheme}>
          <DialogTitle>Are you sure you want to delete this post ?</DialogTitle>
          <DialogActions>
            <Button onClick={() => setShowConfirmation(false)}>Cancel</Button>
            <Button onClick={handleConfirmDelete}>Delete</Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default Posts;
