import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./EndUser.css";
import { useUserAuth } from "../../Context/UseAuthContext";

import { FaUserLarge } from "react-icons/fa6";
import { MdMarkEmailRead } from "react-icons/md";
import { MdLocalPhone } from "react-icons/md";
import { Loading } from "../Loading";
import { FiSearch } from "react-icons/fi";

const EndUsers = () => {
  const [users, setUsers] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [levelSearch, setlevelSearch] = useState("");

  // Added state for search query
  const { darkThemesetter } = useUserAuth();
  const textColor = darkThemesetter ? "dark-text" : "light-text";

  const endUserTheme = darkThemesetter ? "dark-theme" : "light-theme";
  const endUserTextColor = darkThemesetter ? "dark-text" : "light-text";
  // const endUserCards = darkThemesetter
  //   ? "allendusers-card-container-dark-theme"
  //   : "allendusers-card-container";

  const fetchData = async (page) => {
    try {
      // setLoading(true);
      const response = await fetch(
        `https://smartuter.com/api/admin/getallendusers?page=${page}&limit=15&search=${searchQuery}&level=${levelSearch}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }
      const data = await response.json();
      setUsers(data.getUsers);
      setTotalPages(data.pagination.totalPages);
      // setLoading(false);
    } catch (error) {
      setError(error.message);
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, levelSearch, searchQuery]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleVendorSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handlelevelSearchChange = (e) => {
    setlevelSearch(e.target.value);
  };

  // const filteredUsers = users.filter((user) =>
  //   user.name.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  if (false) return <Loading />;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className={`allendusers-container ${endUserTheme}`}>
      <div className="vendor-sub-container-data">
        <div className="SectionDashBoardVendorAnalyticsHeader">
          <h1 className={textColor}>Enduser</h1>
          <p>Welcome to your Enduser, Represented your Enduser data</p>
        </div>

        <div className="search-container">
          <input
            type="text"
            placeholder="Search Enduser..."
            value={searchQuery}
            onChange={handleVendorSearchChange}
          />
          <FiSearch className="mycourse-searchicon" />
        </div>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search Level..."
            value={levelSearch}
            onChange={handlelevelSearchChange}
          />
          <FiSearch className="mycourse-searchicon" />
        </div>
      </div>

      <div className="table-container">
        <table className={`enduser-table ${endUserTextColor}`}>
          <thead>
            <tr>
              <th style={{ width: "20px" }}>S no</th>
              <th>
                Name <FaUserLarge />
              </th>
              <th>
                Email <MdMarkEmailRead />
              </th>
              <th>
                Phone <MdLocalPhone />
              </th>
              <th>Level</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user._id}>
                <td>{(currentPage - 1) * 15 + index + 1}</td>
                <td>
                  <Link to={`/allendusers/${user._id}/${user.name}`}>
                    {user.name}
                  </Link>
                </td>
                <td>{user.email}</td>
                <td>{user.number}</td>
                <td>{user.level}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-controls">
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default EndUsers;
